<template lang="pug">
.form-field-label
  q-input.date-input-class(:value="value", @click.stop="openDateModal()", readonly)
    template(v-slot:prepend)
      inline-svg.datetime-icon(:src="require(`../../../../assets/icons/calendar.svg`)", @click.stop="openDateModal()")
    template(v-slot:append)
      q-icon.cancel-datetime-field(name="cancel", v-if="value", @click.capture="resetField()")
  q-dialog#q-datetime-modal(v-model="openModal")
    q-card.row.radius(flat)
      q-date(
        v-model="currentDate",
        :mask="mask",
        :locale="currentDatesLocale",
        :default-view="'Calendar'",
        today-btn,
        :options="optionsFn"
      )
        .row.justify-end
          q-btn.dialog-controls(
            flat,
            @click="openModal = false",
            :label="buttonCancelLabel",
            v-if="type !== 'datetime'"
          )
          q-btn.dialog-controls(flat, @click="checkDate", :label="buttonCloseLabel", v-if="type !== 'datetime'")
      q-time(v-model="currentDate", :mask="mask", format24h, v-if="type === 'datetime'", now-btn)
        .row.justify-end
          q-btn.dialog-controls(
            flat,
            @click="openModal = false",
            :label="buttonCancelLabel",
            v-if="type === 'datetime'"
          )
          q-btn.dialog-controls(flat, @click="checkDate", :label="buttonCloseLabel", v-if="type === 'datetime'")
</template>

<script setup>
import { Datetime } from "vue-datetime";
import { format, parse } from "date-fns";
import { formatDateAsISO } from "@/services/formatDateAsISO";
import { computed, ref, toRefs, onBeforeMount, watch } from "vue";
import { locales, currentLocale } from "@/services/useLocales";
import i18n from "@/plugins/vue-i18n/index";

const props = defineProps({
  type: { type: String, default: () => "date" },
  value: { type: String, default: () => "" },
  minDate: { type: String, default: () => "" },
  event: { type: Object, default: () => {} },
});

const emit = defineEmits(["select-date"]);

const value = ref(props.value);
const openModal = ref(false);
const currentDate = ref("");

const { minDate } = toRefs(props);

const mask = computed(() => "DD.MM.YYYY");
const type = computed(() => props.type);
const buttonCancelLabel = computed(() => i18n["messages"][currentLocale.value]["buttonCancelLabel"]);
const buttonCloseLabel = computed(() => i18n["messages"][currentLocale.value]["buttonCloseLabel"]);
const currentDatesLocale = computed(() => i18n["messages"][currentLocale.value]["date"]);

const getCurrentDate = () => {
  const date = new Date();
  return format(date, "dd.MM.yyyy");
};

const openDateModal = () => {
  console.log(value.value);
  value.value != "" ? (currentDate.value = value.value) : (currentDate.value = getCurrentDate());
  openModal.value = true;
};

const checkDate = () => {
  value.value = currentDate.value;
  openModal.value = false;
  emitValue(value.value);
};

const emitValue = val => {
  if (props.event) {
    emit("select-date", { val: val, event: props.event });
    return;
  }
  emit("select-date", val);
};

const resetField = () => {
  value.value = "";
  emitValue(value.value);
};

const optionsFn = date => {
  if (minDate.value) {
    const parsedMinDate = parse(minDate.value, "dd.MM.yyyy", new Date());
    return date >= format(parsedMinDate, "yyyy/MM/dd");
  }
};

watch(minDate, async (newVal, oldVal) => {
  if (newVal === "") {
    value.value = "";
    emitValue(value.value);
    return;
  }
  if (newVal > value.value) {
    value.value = newVal;
    emitValue(value.value);
  }
});

onBeforeMount(() => {});
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/date";

.date-input-class {
  border: none !important;
  cursor: pointer;
  .q-field__prepend {
    padding-left: 15px;
    height: 41px;
    path {
      fill: var(--datetime-icon-fill);
    }
  }
  .q-field__append {
    height: 41px;
    i {
      margin-top: 0;
      margin-right: -1px;
      font-size: 14px;
    }
  }
  .q-field__control,
  .q-field__control:before,
  .q-field__control:hover:before {
    color: transparent;
    border: none;
  }
  input {
    font-size: 16px;
    margin-top: 0 !important;
  }
}
#q-datetime-modal {
  .radius {
    border-radius: 20px;
  }
  .q-time,
  .q-date {
    height: 430px;
    box-shadow: none;
    background: var(--vdatetime-popup-background);
    border-radius: 0;
    color: var(--vdatetime-popup-color);
    .q-date__header,
    .q-time__header {
      background-color: var(--vdatetime-popup-day-background-selected);
    }
    .q-time__clock-pointer {
      color: var(--vdatetime-popup-day-background-selected);
    }
    .q-time__clock-position--active {
      background-color: var(--vdatetime-popup-day-background-selected);
    }
    .q-date__content {
      .q-btn {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
    .q-date__calendar-item {
      .bg-primary {
        background-color: var(--vdatetime-popup-day-background-selected) !important;
      }
    }
    .dialog-controls {
      padding-left: 15px;
      padding-right: 15px;
      color: var(--vdatetime-popup-day-background-selected);
      &:nth-child(1) {
        color: var(--delete-button-color);
      }
    }
  }
  .q-date {
    width: 300px;
    .bg-primary {
      background-color: var(--vdatetime-popup-day-background-selected) !important;
    }
  }
  .q-time {
    .q-time__now-button {
      background-color: var(--vdatetime-popup-day-background-selected);
    }
  }
}
</style>

<style scoped lang="scss">
.valid-error-message {
  position: static;
}
</style>
