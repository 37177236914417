<template lang="pug">
.horizontal-filters__field
  .text-bold.text-body1.q-pb-sm.horizontal-filters__field-label {{ field.name }}
    q-icon.text-h6.horizontal-filters__icon-reset(
      name="refresh",
      @click="resetRadioButton",
      v-if="horizontalFiltersRadioFieldValue"
    )
  .horizontal-filters__radio-field-container
    span.horizontal-filters__radio-field(
      v-for="option in props.field.params.options",
      :key="field.field_id + ' ' + option"
    )
      q-radio(
        v-model="horizontalFiltersRadioFieldValue",
        :val="option",
        :label="getFieldLabel(option)",
        @input="setAnswer"
      )
</template>

<script setup>
import { ref } from "vue";

import { techPassportFilterLocales } from "@/services/useLocales";

const props = defineProps({
  field: { type: Object, default: () => {} },
  currentFieldState: { type: Object, default: () => {} },
});

const emit = defineEmits(["answerSet"]);

const horizontalFiltersRadioFieldValue = ref(null);

if (props.currentFieldState.field_id) {
  horizontalFiltersRadioFieldValue.value = props.currentFieldState.inclusion[0];
}

const setAnswer = () => {
  emit("answerSet", {
    field_id: props.field.field_id,
    type: "radio",
    inclusion: horizontalFiltersRadioFieldValue.value ? [horizontalFiltersRadioFieldValue.value] : [],
  });
};

const resetRadioButton = () => {
  horizontalFiltersRadioFieldValue.value = null;
  setAnswer();
};

const getFieldLabel = option => {
  if (option === "true") return techPassportFilterLocales.value.true;
  if (option === "false") return techPassportFilterLocales.value.false;
  return option;
};

const onAllFilterReset = () => {
  horizontalFiltersRadioFieldValue.value = null;
};

defineExpose({ onAllFilterReset });
</script>

<style lang="scss">
.horizontal-filters__radio-field-container {
  margin-left: -10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .horizontal-filters__radio-field {
    margin-right: 20px;
  }
  .q-radio__inner--truthy {
    color: #2f88fc !important;
    .q-radio__bg {
      color: #2f88fc !important;
    }
  }
}
</style>
