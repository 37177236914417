<template lang="pug">
.header
  .header-title-block.row
    template(v-if="isBackButtonVisible")
      .col-1.go-back-button
        q-btn(round, flat, icon="chevron_left", @click="goBack")
      .col-5.header-title.q-my-auto {{ data.header.title }}
    .col-6.header-title(v-else) {{ data.header.title }}
    .col-6.header-actions
      component(
        v-if="parentActions.length > 0",
        v-for="action in parentActions",
        :key="action",
        :is="getActionComponent(action)",
        :parentData="{ grid: grid, item: item, load_filters_params: load_filters_params }",
        @submit="data => $emit('submit', data)"
      )

      theme-handler
      locale-handler(@reload-data="reloadData")
      profile(@reload-data="reloadData")

  .header-title-spacer

  .header-filters-block.row(v-if="!hide_filters || !hide_buttons")
    slot

    grid-filters(
      ref="grid-filters",
      v-if="!hide_filters",
      :parentData="{ path: path, grid: grid, data: data.filters_data, header: data.header }",
      :class="filtersClass"
    )

    grid-button-group(
      v-if="!hide_buttons",
      :parentData="{ grid: grid, grid_key: grid_key, buttons: data.buttons }",
      :class="buttonsGroupClass"
    )
</template>

<script>
import gridButtonGroup from "../grid/gridButtonGroup";
import gridFilters from "../grid/gridFilters";
import Profile from "../../view/profile";
import ThemeHandler from "../../view/theme/edit";
import LocaleHandler from "../../view/locale/edit";
import headerActions from "./header/actions";

export default {
  components: {
    Profile,
    ThemeHandler,
    LocaleHandler,
    gridButtonGroup,
    gridFilters,
    headerActions,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      item: this.parentData.item,
      grid_key: this.parentData.grid_key,
      hide_filters: this.parentData.hide_filters,
      hide_buttons: this.parentData.hide_buttons,
      load_filters_params: this.parentData.load_filters_params || {},
      filtersClass: this.parentData.headerFiltersClass || "col-6",
      buttonsGroupClass: this.parentData.headerButtonsGroupClass || "col-6",
    };
  },

  computed: {
    data() {
      return this.parentData.data;
    },

    parentActions() {
      return this.parentData.actions || [];
    },

    isBackButtonVisible() {
      return this.grid === "workflows" && this.$route.params.id;
    },
  },

  methods: {
    getActionComponent(action) {
      return headerActions[action];
    },

    updateFilter(attr) {
      this.$refs["grid-filters"].updateFilter(attr);
    },

    reloadData() {
      this.$root.$emit("reload-navbar");
      this.$emit("reload-data");
      this.$root.$emit("reload-hidden-message");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.go-back-button {
  width: 60px;
  color: var(--header-title-color);
}
</style>
