<template lang="pug">
div
  .user-edit-form.justify-center(v-if="dataHasLoaded")
    handle-form(
      @close-form="closeForm",
      :parentData="{ method: 'update', item: 'issue_filter', item_id: row.id, callback_params: callbackParams, form_title: `${formData.form_header.title.edit} №${row.id}`, title_icon_path: titleIconPath, path: path, grid: grid, data: formData }"
    )
</template>

<script setup>
import { onBeforeMount, ref } from "vue";

import { useStore } from "@/store";
import { useReLoginRun } from "@/services/useAuth";
import backend from "@/api";

import HandleForm from "@/components/shared/forms/Form";

const store = useStore();

const props = defineProps({
  parentData: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(["close-form", "row-is-active"]);

const path = ref("/api/v3/situational_center/issue_filters");
const grid = ref(props.parentData.grid);
const titleIconPath = ref("edit_header.svg");
const row = ref(props.parentData.row);
const callbackParams = ref(props.parentData.callback_params);
const formData = ref([]);
const dataHasLoaded = ref(false);

const closeForm = data => {
  emit("close-form", data);
};

const loadFormData = async () => {
  const formDataPath = path.value + "/form_data";

  try {
    let params = {};

    if (row.value) {
      params["id"] = row.value.id;
    }

    formData.value = (await backend.index(formDataPath, { params })).data;
  } catch (e) {
    if (e.response) await useReLoginRun(e.response.status);
  } finally {
    dataHasLoaded.value = true;
  }
};

onBeforeMount(async () => {
  await loadFormData();
  emit("row-is-active", true);
});
</script>
