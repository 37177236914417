<template lang="pug">
#store.equipment-tech-map
  .spinner-container.form-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  .tech-map-card(v-if="!loading")
    .main-block
      .actions-block
        .show-card-actions
          div(v-for="button in buttons", :class="`button-wrapper ${button.name}`", :style="button.style.wrapper")
            q-btn.show-card-action(flat, no-caps, @click="updateButtonStyle(button)", :style="button.style.wrapper")
              template(v-if="ifButtonIconExists(button)", slot="default")
                inline-svg(:class="[`${button.name}-icon`]", :style="button.style.icon", :src="getButtonIcon(button)")
              template(v-if="ifButtonLabelExists(button)", slot="default")
                span.action-label {{ button.label }}
          q-btn.materials-import-button(
            @click="$refs['grid-modals'].openImportForm({ data: [], href: '' })",
            flat,
            no-caps
          )
            template(slot="default")
              inline-svg.grid-btn-icon-left(:src="fileIcon")
            template(slot="default", v-if="row && row.works_list")
              span {{ row.works_list.import_button.button_label }}

    .techmap-info-blocks
      .spinner-container.form-spinner(v-if="loadingBlocks")
        q-spinner(color="primary", size="3em")

      .specification-block(v-if="!loadingBlocks")
        specification(
          v-if="row && row.works_list",
          :parentData="{ buttons: data['edit_works_list_buttons'], tab_conf: tab.data, editable: editable, grid: grid, row_id: rowId, data: row && row.works_list ? row.works_list.works_table : [] }",
          @search-or-sort="reloadTable"
        )

    .button-group.row.justify-center(v-if="editable")
      q-btn.tech-map-form-submit(
        flat,
        no-caps,
        :label="data['edit_works_list_buttons']['submit']['label']",
        @click="onSubmit"
      )
  grid-modals(
    ref="grid-modals",
    @reload-data="reloadTable",
    :parentData="{ grid: grid, path: `/api/v3/work_groups/${parentData.row.id}/work_groups_works` }"
  )
</template>

<script>
import specification from "../../specification/specification";
import ImportExcelForm from "@/components/shared/forms/importExcelForm";
import gridModals from "@/components/shared/grid/gridModals";

export default {
  components: {
    ImportExcelForm,
    specification,
    gridModals,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      item: "work_groups",
      rowId: this.parentData.row_id,
      tabName: this.parentData.tab_name,
      row: {},
      data: [],
      editable: false,
      loading: true,
      loadingBlocks: false,
      tab: "",
    };
  },

  computed: {
    buttons() {
      return this.data.actions;
    },

    fileIcon() {
      return require(`../../../../../assets/icons/header/file.svg`);
    },

    techMapData() {
      let grid = this.$store.state.grid[this.grid];
      if (grid) {
        return grid["works_list"]["components"];
      } else {
        return undefined;
      }
    },
  },

  created() {
    this.$store.commit("initialStoringWorks", { grid_name: this.grid });
    this.loadData();
  },

  methods: {
    tabCondition(tabName) {
      return tabName !== "issues" || (tabName === "issues" && this.getData(tabName).length > 0);
    },

    getButtonIcon(button) {
      return require(`../../../../../assets/icons/ppr/equipment/show/actions/${button.icon}`);
    },

    ifButtonIconExists(button) {
      return button.icon && button.icon.length > 0;
    },

    ifButtonLabelExists(button) {
      return button.label && button.label.length > 0;
    },

    getData(tabName) {
      if (tabName) {
        if (tabName === "issues") {
          return this.row[tabName];
        } else {
          return this.row["technological_map"][tabName];
        }
      }
    },

    onSubmit(params = {}) {
      const req = this.techMapData.map(item => ({ id: item.id, cost: item.cost, count: item.count }));

      params[this.item] = {
        work_groups_works: JSON.stringify(req),
      };

      this.loading = true;

      this.$backend
        .update("/api/v3/work_groups/" + this.row.id + "/work_groups_works/update_records", "", params)
        .then(({ data }) => {
          this.row = data;
          this.editable = false;
          this.loading = false;

          this.loadData();
          this.reloadTable();

          this.$q.notify(this.notifies.success_updated_entry);
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.loading = false;
          this.$q.notify(this.notifies.error_ask_admin);
        });
    },

    addCurrentTab(tab) {
      this.tab = tab;
    },

    updateButtonStyle(button) {
      if (button.onclick && button.onclick.child) {
        this.buttons.find(b => b.name === button.name).style.wrapper.display = "none";
        this.buttons.find(b => b.name === button.onclick.child).style.wrapper.display = "initial";
      } else if (button.onclick && button.onclick.parent) {
        this.buttons.find(b => b.name === button.name).style.wrapper.display = "none";
        this.buttons.find(b => b.name === button.onclick.parent).style.wrapper.display = "initial";
      }

      if (button.onclick && button.onclick.fn_name) {
        this[button.onclick.fn_name](button.onclick.attr);
      }
    },

    setEditable(attr) {
      this.editable = attr.value;
      if (!attr.value) {
        this.loadingBlocks = true;
        this.loadData();
      }
    },

    reloadTable(params = false) {
      let searchString = `/api/v3/work_groups/${this.parentData.row.id}/work_groups_works/collection`;
      if (params) {
        const descending = params.sort_direction === "down";
        const getParams = `{"table":{"rowsPerPage":20,"sortBy":"${params.sort}","descending":${descending},"query":"${params.search}","filters":{"work_group_id":${this.parentData.row.id}}}}`;

        searchString = "/api/v3/work_groups/" + this.parentData.row.id + "/work_groups_works" + "?json=" + getParams;
      }

      this.$backend.index(searchString).then(({ data }) => {
        if (params) {
          if (this.row && this.row.works_list) this.row.works_list.works_table[0].data = data.data;
        } else {
          if (this.row && this.row.works_list) this.row.works_list.works_table[0].data = data.options;
        }
      });
    },

    loadData(params = {}) {
      this.$backend
        .show(this.path, this.rowId, { params: params })
        .then(({ data }) => {
          this.row = data["common"]["data"];
          this.data = data["common"]["tabs"].find(tab => tab.name === this.tabName)["data"];
          this.tab = this.data.tabs[0];

          this.$nextTick(() => {
            let keys = Object.keys(this.row.works_list);
            if (keys.length > 0) {
              keys.forEach(key => {
                if (key === "works_list") {
                  if (this.row && this.row["works_table"][key].map(s => s.required).length > 0) {
                    this.row["works_table"][key].map(s => delete s.required);
                  }
                  if (this.row && this.row["works_table"][key].filter(el => el.name).length > 0) {
                    this.row["works_table"][key] = this.rewriteKeysTempLogic(this.row["technological_map"][key]);
                  }
                }

                this.$store.commit("addWorkingGroupWorks", {
                  grid_name: this.grid,
                  tech_map_key: key,
                  attr: this.row && this.row["works_list"] ? this.row["works_list"]["works_table"][0]["data"] : [],
                });
              });
            }
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingBlocks = false;
        });
    },

    rewriteKeysTempLogic(obj) {
      return obj.map(el => {
        return Object.keys(el).reduce((r, key) => {
          if (key === "name") {
            r["key"] = el[key];
          } else {
            r[key] = el[key];
          }
          return r;
        }, {});
      });
    },
  },
};
</script>

<style lang="scss">
#store .techmap-info-blocks,
#store .equipment-tech-map,
#store .tabs-block {
  min-height: 0;
}

#store {
  .specification-title span {
    font-size: 20px !important;
    color: var(--tab-text-color) !important;
  }
  &.equipment-tech-map .tech-map-card .specification-block {
    margin-top: 58px;
  }
  .equipment-tech-map {
    position: relative;
    .tech-map-card {
      .tabs-block {
        max-height: 320px;
        margin-top: 0;
        td {
          padding: 10px;
        }
        thead {
          position: absolute;
          display: table;
          table-layout: fixed;
          top: -40px;
        }
        tr {
          display: table;
          table-layout: fixed;
          width: 100%;
        }
      }
    }
  }
}

.materials-import-button {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 10px;
  border-radius: 8px;
  background-color: #5b7aff;
  color: #fff;
}

#components-table.stores td {
  color: var(--tab-text-color);
}
</style>
