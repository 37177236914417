<template lang="pug">
q-card.horizontal-filters-list__item__card
  .row
    .col-3.horizontal-filters-list__flex-modifier
      .horizontal-filters-list__colored-icon
        q-icon(:name="getIconName(filter.type)")
    .col-5.horizontal-filters-list__flex-modifier.text-body1
      span.horizontal-filters-list__title {{ filter.title }}
    .col-2.horizontal-filters-list__flex-modifier.justify-end
      span.horizontal-filters-list__counter {{ filter.count }}
    .col-2.horizontal-filters-list__flex-modifier
      q-icon.horizontal-filters-list__filter-icon(
        name="filter_list",
        @click="triggerIsFilterModalOpen(true)",
        :class="{ 'horizontal-filters-list__filter-icon--active': isFilterActive }"
      )
      HorizontalFiltersFormCard(
        :isFilterModalOpen="isFilterModalOpen",
        :title="filter.title",
        :fields="fields",
        :currentFields="answers",
        @hideFilterModal="triggerIsFilterModalOpen(false)",
        @answerSet="onAnswerSet",
        @resetAllFields="onAllFieldsReset",
        @filterResults="onFilterResults"
      )
</template>

<script setup>
import { ref } from "vue";

import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";

import HorizontalFiltersFormCard from "../horizontalFiltersList/horizontalFiltersForm/HorizontalFiltersFormCard";

const props = defineProps({
  filter: { type: Object, default: () => {} },
  iconsByTypes: { type: Object, default: () => {} },
  isFilterActive: { type: Boolean, default: false },
  fieldsPath: { type: String, default: "" },
});

const emit = defineEmits(["filterResults"]);

const fields = ref([]);
const answers = ref([]);

const searchQuery = ref("");

const areFieldsLoaded = ref(false);
const isFilterModalOpen = ref(false);

const triggerIsFilterModalOpen = val => {
  if (!areFieldsLoaded.value) {
    loadFields();
    areFieldsLoaded.value = true;
  }
  isFilterModalOpen.value = val;
};

const getIconName = type => {
  return props.iconsByTypes[type] || "search";
};

const loadFields = async () => {
  try {
    fields.value = (await backend.index(props.fieldsPath)).data;
  } catch (error) {
    await handleError(error);
  }
};

const onAnswerSet = val => {
  const answerIndex = answers.value.findIndex(elem => elem.field_id === val.field_id);
  if (answerIndex === -1) {
    if (
      (val.inclusion && val.inclusion.length !== 0) ||
      (!val.inclusion && val.min !== undefined && val.max !== undefined)
    ) {
      answers.value.push(val);
    }
  } else {
    if (
      (val.inclusion && val.inclusion.length === 0) ||
      (!val.inclusion && val.min === undefined && val.max === undefined)
    ) {
      answers.value.splice(answerIndex, 1);
    } else {
      answers.value[answerIndex] = val;
    }
  }
};

const onAllFieldsReset = () => {
  answers.value = [];
};

const onFilterResults = () => {
  emit("filterResults", { answers: answers.value, type: props.filter.type });
};
</script>

<style lang="scss">
.horizontal-filters__field {
  margin-bottom: 15px;
}
.horizontal-filters-list {
  &__item__card {
    box-shadow: none;
    border: 1px solid #9599e5;
    border-radius: 20px;
    background: var(--horizontal-filters-card-background);
    width: 308px;
    padding: 10px 15px 10px 10px;
  }

  &__search-input {
    width: 100%;
    height: 40px;
    border: 1px solid lightgrey;
    border-radius: 15px;
    color: var(--horizontal-filters-form-card-color) !important;
    input {
      color: var(--horizontal-filters-form-card-color);
    }
    .q-field__control {
      height: 40px;
      &:before {
        border: none;
      }

      &:after {
        background: none !important;
      }
    }

    .q-field__prepend {
      height: 40px;
      margin-left: 10px !important;
      margin-right: 10px !important;
      padding-right: 0 !important;
      color: var(--horizontal-filters-form-card-color);
    }
  }

  &__flex-modifier {
    display: flex;
    align-items: center;
  }

  &__colored-icon {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background: #2f88fc;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  &__filter-icon {
    color: #2f88fc;
    font-size: 27px;
    cursor: pointer;
    border-radius: 20px;
    padding: 5px;
    background: transparent;
    transition: all 0.3s;
    &:hover {
      background: var(--horizontal-filters-card-icon-background-hover);
    }
    &:active {
      background: var(--horizontal-filters-card-icon-background-hover);
    }
    &--active {
      background: #2f88fc;
      color: white;
      transition: all 0.3s;
      &:hover {
        background: #4f9cff;
      }
      &:active {
        background: #569ffd;
      }
    }
  }

  &__title {
    font-size: 18px;
    margin-left: -20px;
    color: var(--horizontal-filters-card-color);
  }

  &__counter {
    font-size: 20px;
    font-weight: 600;
    color: var(--horizontal-filters-card-color);
  }
}

.horizontal-filters-list__item:nth-of-type(1n) .horizontal-filters-list__colored-icon {
  background-color: #3580ff;
}

.horizontal-filters-list__item:nth-of-type(2n) .horizontal-filters-list__colored-icon {
  background-color: #1da395;
}

.horizontal-filters-list__item:nth-of-type(3n) .horizontal-filters-list__colored-icon {
  background-color: #ff8283;
}

.horizontal-filters-list__item:nth-of-type(4n) .horizontal-filters-list__colored-icon {
  background-color: #bb6bd9;
}

.horizontal-filters-list__item:nth-of-type(5n) .horizontal-filters-list__colored-icon {
  background-color: #fbb76c;
}
</style>
