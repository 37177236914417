<template lang="pug">
span.date-viewer {{ formattedDate }}
</template>

<script setup>
import { computed } from "vue";
import { parseISO, format, parse } from "date-fns";

const props = defineProps({
  label: { type: String, default: "" },
});

const formattedDate = computed(() => {
  try {
    return format(parseISO(props.label), "dd.MM.yyyy");
  } catch (e) {
    // Since we set date from backend and format it to match internal format (date_filter.vue)
    // we receive format which is not ISO format anymore. In that case, fallback to a string (it would be dd.mm.yyyy)
    return props.label;
  }
});
</script>

<style scoped lang="scss">
.date-viewer {
  border: 2px solid #e1e3ec;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 16px 9px;
  font-weight: 700;
  margin-left: 1em;
  color: var(--selected-date-label);
}
</style>
