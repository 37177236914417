<template lang="pug">
div(:class="default_class")
  div(
    v-if="default_class === 'grid-actions'",
    :id="`grid-actions-btn-${index}`",
    :class="[can_handle || can_crud ? 'grid-actions-btn' : 'grid-actions-btn ' + 'disabled-field']"
  )
    inline-svg.grid-actions-icon(:src="require(`../../../assets/icons/${actions_icon_path}`)")

    grid-actions-menu(
      ref="grid-actions-menu",
      @handlers-length="setHandlersLength",
      @destroy-row="destroyRow",
      @click-handle-action="clickHandleAction",
      @open-form="openForm",
      @open-show="openShow",
      :parentData="{ grid: grid, grid_key: grid_key, actions: actions, row: row }"
    )

  q-btn(
    v-else,
    flat,
    no-caps,
    :id="'action-btn-' + btn_index",
    v-for="key in Object.keys(actions)",
    :key="key",
    @click="onClickAction()",
    :disabled="disableBtn(key)"
  )
    template(slot="default")
      inline-svg.add-actions-icon(:src="require(`../../../assets/icons/${actions_icon_path}`)")
    template(v-if="btn_label", slot="default")
      span.action-label {{ btn_label }}
    template(v-if="btn_label", slot="default")
      inline-svg.arrow-down-actions-icon(:src="require(`../../../assets/icons/dropdown.svg`)")

    grid-actions-menu(
      ref="grid-actions-menu",
      @handlers-length="setHandlersLength",
      @destroy-row="destroyRow",
      @click-handle-action="clickHandleAction",
      @open-form="openForm",
      @open-show="openShow",
      :parentData="{ index: btn_index, grid: grid, grid_key: grid_key, actions: actions, row: row }"
    )

  q-dialog(ref="transition_modal", v-model="modal.transition_form", @hide="cancelTransition")
    transition-form(
      ref="transition_form",
      @row-is-active="rowIsActive",
      @send-transition="handleTransition",
      @cancel-transition="cancelTransition",
      :parentData="{ grid: grid, path: path, action: current_action, row: row, transition_key: transition_key }"
    )

  q-dialog(v-model="confirm_modal")
    confirm-modal(@confirm="confirmResult", :parentData="{ message: confirm_message }")
</template>

<script>
import gridActionsMenu from "./gridActionsMenu";
import transitionForm from "../forms/transitionForm";
import { toObjectPass } from "@/services/useActionHandlers.js";

import { useEmitter } from "@/services/useEmitter";

export default {
  components: {
    gridActionsMenu,
    transitionForm,
    confirmModal: () => import("../helpers/confirmModal"),
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      index: this.parentData.index,
      btn_label: this.parentData.btn_label,
      default_class: this.parentData.default_class || "",
      actions_icon_path: this.parentData.actions_icon_path,
      path: this.parentData.path,
      grid: this.parentData.grid,
      transition_key: this.parentData.transition_key,
      grid_key: this.parentData.grid_key,
      btn_index: this.parentData.index,
      current_action: {},

      disabled_btn: false,

      modal: {
        transition_form: false,
      },

      confirm_modal: false,
      confirm_message: "",
      destroy_data: undefined,

      handle_actions_length: 0,

      actionHandlers: {
        toObjectPass,
      },

      emitter: useEmitter(),
    };
  },

  computed: {
    actions() {
      return this.parentData.actions;
    },

    handlers_length: {
      get() {
        return this.handle_actions_length;
      },

      set(length) {
        this.handle_actions_length = length || 0;
      },
    },

    can_handle() {
      return this.handlers_length > 0;
    },

    can_crud() {
      return this.actions.crud && Object.keys(this.actions.crud).length > 0;
    },

    row() {
      return this.parentData.row;
    },
  },

  methods: {
    setHandlersLength(attr) {
      this.handlers_length = attr.length;
    },

    disableBtn(key) {
      return !this["can_" + key];
    },

    async copyWorkflow(params = {}) {
      const resp = await this.$backend.create(`/api/v3/workflows/${params.row_id}/copy`);
      const attr = { ...this.row };
      attr.id = resp.data.id;

      this.$emit("refresh-table", { attr: attr, action: "create" });
      return resp;
    },

    async constructorWorkflow(params = {}) {
      await this.$router.push({ name: "WorkflowConstructor", params: { id: params.row_id } });
    },

    // refreshRow(row) {
    //   this.row = row
    //   this.$refs['grid-actions-menu'].refreshRow(row)
    //   // this.$refs['transition_form'].refreshRow(row)
    // },

    rowIsActive(val) {
      this.$emit("set-callback-row", val ? this.row : {});
      this.$emit("row-is-active", val);
    },

    async clickHandleAction(action) {
      let attr = {};

      if (action.form === "transition_form") {
        this.current_action = action;
        this.modal.transition_form = true;
      } else if (action.fn) {
        attr = {
          row_id: this.row.id,
          row: this.row,
          action: action,
        };
        if (this.grid === "workflows") {
          await this[action.fn](attr);
        } else {
          this.actionHandlers[action.fn](attr);
        }
      } else if (action.form === "templates_form") {
        attr = {
          form_type: action.form,
          row: this.row,
          callback_params: {
            key: action.key,
          },
        };
        this.$emit("open-form", attr);
      } else {
        attr = {
          form_type: action.form,
          row: this.row,
          callback_params: {},
        };
        this.$emit("open-form", attr);
      }
    },

    cancelTransition() {
      this.current_action = {};
      this.$emit("set-callback-row", {});
      this.$refs.transition_modal.hide();
    },

    handleTransition(params) {
      let row_name = this.transition_key;

      this.current_action = {};

      let req_params = {};
      req_params[row_name] = params[row_name];

      this.$refs.transition_form.loadingForm(true);

      this.$backend
        .update(this.path, params.id, req_params)
        .then(res => {
          if (res.data) {
            this.$nextTick(() => {
              if (
                (this.grid === "issues" || this.grid === "ppr_issues") && res.data.data
                  ? res.data.data.terminated === true || res.data.data.need_destroy
                  : res.data.terminated === true || res.data.need_destroy
              ) {
                this.$emit("refresh-table", { attr: res.data, action: "destroy" });
                this.$q.notify(this.notifies.success_updated_entry);
              } else {
                this.$emit("refresh-table", { attr: res.data, action: "update" });
              }
              this.$emit("set-callback-row", {});
              this.$emit("row-is-active", false);
              this.$root.$emit("load-show-data", res.data.data);
              this.emitter.emit("load-show-data", res.data.data);
              if (this.transition_key === "measuring_meter") {
                this.$root.$emit("reload-measuring-last-readings");
              }

              this.$refs.transition_form.loadingForm(false);
              this.$refs.transition_modal.hide();
            });
          }
        })
        .catch(error => {
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.$refs.transition_form.loadingForm(false);

          // this.$refs.transition_modal.hide()

          let base_errors = error.response.data.errors.base;
          if (base_errors) {
            base_errors.forEach(e => {
              this.$q.notify(e.message || e);
            });
          } else {
            this.$q.notify(this.notifies.error_ask_admin);
          }

          console.log(error);
        });
    },

    onClickAction() {
      setTimeout(() => {
        let button = document.getElementById("action-btn-" + this.btn_index);
        let menu = document.getElementById("menu-" + this.btn_index);

        if (menu && button) {
          menu.style.width = button.clientWidth + "px";
        }
      }, 50);
    },

    destroyRow(data) {
      this.destroy_data = data;
      this.confirm_message =
        this.grid === "workflows" ? this.notifies.confirm_archive_entry : this.notifies.confirm_destroy_entry;
      this.confirm_modal = true;
    },

    confirmResult(val) {
      if (val) {
        let action = this.destroy_data.action;
        let path = action && action.path ? `/api/v3/${action.path}` : this.path;
        this.$backend
          .destroy(path, this.destroy_data.row.id)
          .then(() => {
            this.$nextTick(() => {
              this.$emit("refresh-table", { attr: { id: this.destroy_data.row.id }, action: "destroy" });
              this.grid === "workflows"
                ? this.$q.notify(this.notifies.success_archived_entry)
                : this.$q.notify(this.notifies.success_deleted_entry);
            });
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.$q.notify(this.notifies.error_ask_admin);
          })
          .finally(() => {
            this.destroy_data = undefined;
            this.confirm_modal = false;
          });
      } else {
        this.destroy_data = undefined;
        this.confirm_modal = false;
      }
    },

    openForm(attr) {
      this.$emit("open-form", attr);
    },

    openShow(row) {
      if (row.show_tab) {
        this.$root.$emit("load-show-data", row);
        this.emitter.emit("load-show-data", row);
      }
      this.$emit("open-show", row);
    },
  },
};
</script>
