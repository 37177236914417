<template lang="pug">
div
  index-page(
    @set-loading="setLoading",
    :parentData="{ grid: grid, path: path, grid_key: grid_key, report_class: report_class, report_namespace: report_namespace }"
  )

  ui-prompts(:parentData="{ areas_key: grid }")
</template>

<script>
import indexPage from "../../../shared/indexPage";
import uiPrompts from "../../../shared/ui_prompts/index.vue";

export default {
  components: {
    indexPage,
    uiPrompts,
  },
  data: function () {
    return {
      grid: "ppr_systems",
      report_class: "system",
      report_namespace: "ppr",
      path: this.$store.state.paths["ppr_system"],
      grid_key: "data",
    };
  },

  beforeMount() {
    // this.$emit('set-loading', false)
    this.$emit("expand-class", { val: false, class: "" });
  },

  methods: {
    setLoading(val) {
      this.$emit("set-loading", val);
    },
  },
};
</script>

<style lang="scss"></style>
