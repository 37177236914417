<template lang="pug">
.issues
  .issues__metrics(v-if="metricsLoading")
    q-skeleton(v-for="metric in metrics", :key="metric.key", width="25%", height="60px", type="QChip")

  .issues__metrics(v-else)
    .metrics-card(v-for="metric in metrics", :key="metric.key")
      span.metrics-card__label {{ metric.label }}
      span.metrics-card__value.text-weight-bold {{ metric.value }}

  .issues__charts
    .chart(v-for="name in chartNames", :key="name")
      component(
        :ref="name",
        :is="getChart(name)",
        :loading="chartLoadings[name]",
        :label="getChartLabel(name)",
        :value="getChartValue(name)"
      ) 

  .issues__service-metrics 
    .service-metrics(v-for="list in chunkedServices", :key="`chunk-${list[0]?.id}`")
      service-card(
        v-for="service in list",
        :key="service.id",
        :loading="serviceMetricsLoading",
        :hidden="hiddenServiceMetric(service)",
        :locales-content="dashboardLocales",
        :filters-content="dashboardFilters",
        :total-count="serviceMetricCount(service)",
        :service="service",
        :issue-counts="serviceMetricItems(service)",
        @show="changeLayout('expand', list, $event)",
        @hide="changeLayout('collapse', list, $event)"
      )
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";
import { buildUrlParams } from "@/services/urlParams";
import { deepMapKeysCamelCase, mapKeysCamelCase } from "@/services/caseConvert";
import { useStore } from "@/store";
import { useRouter } from "@/routing/router";
import _ from "lodash";
import ratesCsi from "./issues/charts/ratesCsi.vue";
import issueCreateCounts from "./issues/charts/issueCreateCounts.vue";
import serviceCard from "./issues/serviceCard.vue";

const props = defineProps({
  facilityId: { type: Number, default: -1 },
});

const emit = defineEmits(["set-title", "loaded"]);
const store = useStore();
const router = useRouter();
const grid = "issue_dashboard_structure";

const facilityId = ref(-1); // dynamic value of current facility
const dashboardStructure = ref({});
const dashboardFilters = ref({}); // store current values of filters from modal
const chartLoadings = ref({});
const serviceMetricsLoading = ref(true);
const metricsLoading = ref(true);
const loading = ref(true);
const metrics = ref([]);
const charts = ref({});
const serviceMetrics = ref({});
const expandedServiceMetricsIds = ref([]);
const hiddenServiceMetricsIds = ref([]);

const chartNames = computed(() => dashboardStructure.value.charts);
const dashboardLocales = computed(() => dashboardStructure.value.locales);
const chunkedServices = computed(() => _.chunk(dashboardStructure.value.serviceMetrics, 3));

const setupLoadings = () => {
  metricsLoading.value = true;
  serviceMetricsLoading.value = true;
};

const setupStructure = () => {
  // Init metrics to show blank boxes
  metrics.value = dashboardStructure.value.metrics.map(name => ({ key: name, label: "", value: undefined }));

  // Set charts loading
  chartNames.value.forEach(name => (chartLoadings.value[name] = true));
};

const serviceMetricCount = service => {
  return serviceMetrics.value[service.id]?.totalCount || 0;
};

const serviceMetricItems = service => {
  return serviceMetrics.value[service.id]?.items || {};
};

const getChart = name => {
  if (!name) {
    return;
  }

  const chartName = _.camelCase(name); // receive name from backend in snake_case, convert to camelCase to find component

  const components = {
    ratesCsi: ratesCsi,
    issueCreateCounts: issueCreateCounts,
  };

  return components[chartName];
};

const getChartLabel = name => {
  return charts.value[name]?.label;
};

const getChartValue = name => {
  return charts.value[name]?.value;
};

const updateMetric = (key, label, value) => {
  metrics.value = metrics.value.map(metric => (metric.key === key ? { ...metric, label, value } : metric));
};

const changeLayout = (mode, row, serviceId) => {
  // Find siblings in the same row to hide them on expand a card and show them on collapse
  const siblingMetricIdsInRow = row.filter(metric => metric.id !== serviceId).map(metric => metric.id);

  if (mode === "expand") {
    expandedServiceMetricsIds.value.push(serviceId);
    hiddenServiceMetricsIds.value.push(...siblingMetricIdsInRow);
  } else {
    expandedServiceMetricsIds.value = expandedServiceMetricsIds.value.filter(id => id !== serviceId);
    hiddenServiceMetricsIds.value = hiddenServiceMetricsIds.value.filter(id => !siblingMetricIdsInRow.includes(id));
  }
};

const hiddenServiceMetric = service => {
  const serviceExistsInHiddenList = hiddenServiceMetricsIds.value.findIndex(id => id === service.id) >= 0;

  return serviceExistsInHiddenList;
};

const loadStructure = async () => {
  loading.value = true;

  try {
    const response = await backend.index(`/api/v3/issues_dashboard/structure?facility_id=${facilityId.value}`);
    dashboardStructure.value = deepMapKeysCamelCase(response.data);
    setupStructure();
    emit("set-title", response.data.header.title); // set dashboard title
  } catch (error) {
    loading.value = false;
    await handleError(error);
  }
};

const loadMetrics = async filters => {
  const metricNames = metrics.value.map(metric => metric.key);

  for (const name of metricNames) {
    try {
      const params = buildUrlParams({ filters, type: "issue_metric", value: name });
      const response = await backend.index(`/api/v3/issues_dashboard/item?${params}`);
      const backendMetric = response.data;
      updateMetric(name, backendMetric.label, backendMetric.value);
    } catch (error) {
      await handleError(error);
    }
  }

  metricsLoading.value = false;
};

const loadCharts = async filters => {
  for (const name of chartNames.value) {
    try {
      const params = chartParams(name, filters);
      const response = await backend.index(`/api/v3/issues_dashboard/item?${params}`);
      charts.value = { ...charts.value, [name]: response.data };
    } catch (error) {
      await handleError(error);
    } finally {
      chartLoadings.value[name] = false;
    }
  }
};

const loadServiceMetrics = async filters => {
  for (const service of dashboardStructure.value.serviceMetrics) {
    try {
      const params = buildUrlParams({ filters, type: "service_metric", value: service.id });
      const response = await backend.index(`/api/v3/issues_dashboard/item?${params}`);
      const convertedResponse = mapKeysCamelCase(response.data.value);
      serviceMetrics.value = { ...serviceMetrics.value, [service.id]: convertedResponse };
    } catch (error) {
      await handleError(error);
    }
  }

  serviceMetricsLoading.value = false;
};

const chartParams = (chartName, filters) => {
  let finalFilters = {};

  if (chartName == "issue_create_counts") {
    const cleanFilters = _.omit(filters, ["dateFrom", "dateTo"]);
    finalFilters = { createAtFrom: filters["dateFrom"], createAtTo: filters["dateTo"], ...cleanFilters };
  } else {
    finalFilters = filters;
  }

  const params = buildUrlParams({ filters: finalFilters, type: "chart", value: chartName });

  return params;
};

const loadItems = async filters => {
  dashboardFilters.value = deepMapKeysCamelCase(filters);

  await loadMetrics(filters);
  await loadCharts(filters);
  await loadServiceMetrics(filters);
};

// Fetched by parent, when filters changed. Keys are in camel case
const updateContent = async filters => {
  setupLoadings();

  if (facilityId.value !== filters.facilityId) {
    // Load new page with new facility
    router.push({ name: "Dashboard", params: { facility_id: filters.facilityId, type: "issues" } });
  }

  await loadItems(filters);
};

const loadInitialContent = async () => {
  facilityId.value = props.facilityId; // Set initial facility to compare with filters change

  setupLoadings();
  await loadStructure();
  emit("loaded");
  await loadItems(dashboardStructure.value.filtersDefaultValues);
};

onMounted(async () => {
  await loadInitialContent();
});

defineExpose({ updateContent });
</script>

<style scoped lang="scss">
// Apexcharts styles
.chart {
  :deep(.apexcharts-gridlines-horizontal) {
    // Remove dash line near x axis as it looks terrible. We cannot change it by library's configs.
    .apexcharts-gridline:last-child {
      display: none;
    }
  }
}

.issues {
  padding-top: 15px;

  .section-name {
    font-weight: 400;
    font-size: 16px;
    color: var(--issue-dashboard-section-name-color);
  }

  .issues__metrics {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: stretch;
    height: 60px;

    .metrics-card {
      padding: 16px 30px;
      background: var(--issue-dashboard-metrics-card-background);
      color: var(--issue-dashboard-metrics-card-color);
      border: 1px solid #dfdfe9;
      border-radius: 20px;
      align-items: center;
      display: flex;
      flex-grow: 1;
    }

    .metrics-card__label {
      &:first-letter {
        text-transform: capitalize;
      }
    }

    .metrics-card__value {
      margin-left: auto;
    }
  }

  .issues__charts {
    margin-top: 30px;

    .chart {
      &:nth-child(even) {
        margin-top: 15px;
      }
    }
  }

  .issues__service-metrics {
    margin-top: 30px;

    .service-metrics {
      display: flex;

      &:not(:first-child) {
        margin-top: 25px;
      }
    }
  }
}
</style>
