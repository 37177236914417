<template lang="pug">
.form-step-three.form-step
  .form-step-three-text.title {{ schedulerLocales["step3"] }}
    span {{ schedulerLocales["ppr"] }}
    span.form-step-three-text-blue {{ schedulerLocales["groupSystem"] }}
    q-icon(name="arrow_right_alt")
    span.form-step-three-text-blue {{ schedulerLocales["system"] }}
  .form-step-three-selects
    .form-step-three-selects-item
      select-field(
        :full-options-path="store.state.paths['ppr_group_system']",
        :readonly="groupSystemReadonly",
        :multiple="false",
        :initialValue="groupSystem ? groupSystem.id : null",
        :optionsParams="groupSystemParams",
        :watch="groupSystemWatch",
        :showCancel="false",
        :label="schedulerLocales['groupSystem']",
        @change="updateGroupSystem"
      )
    .form-step-three-selects-item
      select-field(
        :full-options-path="store.state.paths['ppr_system']",
        :readonly="systemReadonly",
        :multiple="true",
        :initialValue="systems",
        :optionsParams="systemParams",
        :watch="systemWatch",
        :showCancel="false",
        :label="schedulerLocales['system']",
        @change="updateSystem"
      )
  .form-step-three-radio
    q-radio(v-model="type", dense, val="building", :label="schedulerLocales['objectPlanning']")
    q-radio(v-model="type", dense, val="equipment", :label="schedulerLocales['equipmentPlanning']")
  .form-step-three-equipment(v-if="type === 'equipment'")
    select-field(
      :full-options-path="store.state.paths['ppr_equipment']",
      :readonly="equipmentReadonly",
      :multiple="true",
      :initialValue="equipments",
      :optionsParams="equipmentParams",
      :multiwatch="equipmentWatch",
      :showCancel="false",
      :canPaste="true",
      :label="schedulerLocales['equipment']",
      required,
      @change="updateEquipment"
    )
</template>

<script setup>
import { computed, ref, toRefs, watch } from "vue";
import i18n from "@/plugins/vue-i18n";
import selectField from "../../../../shared/general_components/fields/selectField";
import { useStore } from "@/store";
import { currentLocale } from "@/services/useLocales";

const props = defineProps({
  groupSystem: { type: Object, default: () => null },
  systems: { type: Array, default: () => [] },
  equipments: { type: Array, default: () => [] },
  groupSystemReadonly: { type: Boolean, default: () => true },
  systemReadonly: { type: Boolean, default: () => true },
  equipmentReadonly: { type: Boolean, default: () => true },
  groupSystemWatch: { type: Object, default: () => null },
  systemWatch: { type: Object, default: () => null },
  equipmentWatch: { type: Array, default: () => null },
  groupSystemParams: { type: Object, default: () => null },
  systemParams: { type: Object, default: () => null },
  equipmentParams: { type: Object, default: () => null },
  radioType: { type: String, default: () => "building" },
});
const emit = defineEmits(["update-group-system", "update-system", "update-equipment", "update-radio"]);
const store = useStore();

const schedulerLocales = computed(() => i18n["messages"][currentLocale.value]["scheduler"]);

const type = ref(props.radioType);

const { systems } = toRefs(props);
const { equipments } = toRefs(props);
const { groupSystemReadonly } = toRefs(props);
const { systemReadonly } = toRefs(props);
const { equipmentReadonly } = toRefs(props);
const { groupSystemWatch } = toRefs(props);
const { systemWatch } = toRefs(props);
const { equipmentWatch } = toRefs(props);
const { groupSystemParams } = toRefs(props);
const { systemParams } = toRefs(props);
const { equipmentParams } = toRefs(props);

const updateGroupSystem = val => {
  emit("update-group-system", val);
};

const updateSystem = val => {
  emit("update-system", val);
};

const updateEquipment = val => {
  emit("update-equipment", val);
};

watch(type, (newVal, oldVal) => {
  emit("update-radio", newVal);
});
</script>
