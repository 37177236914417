import showIssue from "../../view/issues/show";
import showIssueCenter from "../../view/issue_center/show";
import showContract from "../../view/contracts/show";
import showPprIssue from "../../view/ppr/issues/show";
import showPprEquipment from "../../view/ppr/equipments/show.vue";
import showPprWorkGroup from "../../view/ppr/work_groups/show";
import showMeasuringMeter from "../../view/measuring/meters/show";
import showCompany from "../../view/companies/show";
import showStore from "../../view/stores/show";
import showWorkGroup from "../../view/work_groups/show";
import showRoom from "../../view/rooms/show";

export default {
  issues: showIssue,
  archive: showIssue,
  ppr_issues: showPprIssue,
  ppr_archive: showPprIssue,
  ppr_equipments: showPprEquipment,
  ppr_work_groups: showPprWorkGroup,
  contracts: showContract,
  measuring_meters: showMeasuringMeter,
  companies: showCompany,
  stores: showStore,
  work_groups: showWorkGroup,
  issue_center: showIssueCenter,
  rooms: showRoom,
};
