<template lang="pug">
.column-chart
  .column-chart__header
    .column-chart__label {{ value?.legend?.title }}
    .column-chart__legend 
      .column-chart__legend__item(v-for="item in value?.legend?.items", :key="item.title")
        .column-chart__legend__item__circle(:style="`background-color: ${item.color}`")
        .column-chart__legend__item__label {{ item.title }}

  apexchart(type="candlestick", :height="300", :options="chartOptions", :series="series")
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { backend } from "@/api";
import { useEmitter } from "@/services/useEmitter";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { useStore } from "@/store";

import { currentLocale } from "@/services/useLocales";

import i18n from "@/plugins/vue-i18n";

const emitter = useEmitter();

const store = useStore();

const props = defineProps({
  path: { type: String, required: true },
});

const chartOptions = ref({
  chart: {
    type: "candlestick",
    height: 350,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    tooltip: {
      enabled: false,
    },
  },
  legend: {
    show: false,
  },
  plotOptions: {
    candlestick: {
      colors: {
        upward: "#859DFF",
        downward: "#71C79F",
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -10,
    custom: opts => {
      const low = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].y[2];
      let text = `<span class='column-chart__bar__label'>${low}</span>`;

      return text;
    },
  },
  tooltip: {
    followCursor: false,
    custom: function (opts) {
      // candlestick standard
      const date = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].x;
      const open = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].y[0];
      const high = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].y[1];
      const low = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].y[2];
      const close = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].y[3];

      let text =
        "<div class='column-chart__tooltip'><div class='column-chart__tooltip__header'><span class='column-chart__tooltip__circle'></span>";
      text += `<span class='column-chart__tooltip__label'>${low}</span></div>`;
      text += `<span class='column-chart__tooltip__value'>${date}</span></div>`;

      return text;
    },
  },
});

const series = ref([
  // {
  //   name: "candle",
  //   data: [
  //     {
  //       x: "Август",
  //       y: [0, 0, 554706, 554706],
  //     },
  //     {
  //       x: "Ctynz,h",
  //       y: [554706, 554706, 0, 0],
  //     },
  //   ]
  // },
]);

const value = ref({});

const getMonth = month => {
  return i18n["messages"][currentLocale.value]["date"]["months"][month];
};

const getData = async () => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["expenses_dashboard"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  const data = (
    await backend.collection(props.path, {
      query: localQuery,
      filters: JSON.stringify(localFilters),
    })
  ).data;

  console.log(data);
  console.log(data.data);

  let lastStart = 0;

  const newArr = [];

  for (let i = 0; i < data.data.length; i++) {
    newArr.push({
      x: getMonth(new Date(data.data[i].x).getMonth()),
      y: [lastStart, lastStart, Math.ceil(data.data[i].y + lastStart), Math.ceil(data.data[i].y + lastStart)],
    });
    lastStart = Math.ceil(data.data[i].y + lastStart);
  }

  newArr.push({ x: data.total.label, y: [0, 0, Math.ceil(data.total.data), Math.ceil(data.total.data)] });

  console.log(newArr);

  series.value = [{ name: "candle", data: newArr }];

  value.value = data;
};

onMounted(async () => {
  await getData();

  emitter.on("refresh-expenses-dashboard-data", getData);
});

onBeforeUnmount(() => {
  emitter.off("refresh-expenses-dashboard-data");
});
</script>

<style lang="scss">
.column-chart {
  width: 100%;

  background: var(--main-background-color);
  box-shadow: var(--expenses-dashboard-row-item-shadow);
  border: 1px solid gray;

  border-radius: 20px;

  &__header {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &__legend {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      align-items: center;
      gap: 5px;

      &__circle {
        width: 8px;
        height: 8px;

        border-radius: 50%;

        background-color: #5b7aff;
      }
      &__label {
        font-weight: 40;
        font-size: 14px;
        color: #8a9099;
      }
    }
  }

  &__label {
    color: var(--header-title-color);
    font-size: 20px;
  }
  &__tooltip {
    padding: 15px 20px;
    background-color: var(--expenses-dashboard-custom-back);

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }

    &__circle {
      width: 8px;
      height: 8px;

      border-radius: 50%;

      background-color: #5b7aff;
    }

    &__label {
      font-weight: 400;
      font-size: 14px;
      color: #8a9099;
    }
    &__value {
      font-weight: 400;
      font-size: 14px;
      color: var(--header-title-color);
    }
  }
  & .apexcharts-datalabel {
    font-weight: 400;
    fill: #5b7aff !important;
  }

  & .apexcharts-data-labels {
    rect {
      display: none;
    }
  }
  & .apexcharts-series path {
    clip-path: inset(0 0 0 0 round 5px);
    &:last-child {
      fill: #71c79f;
      stroke: none;
    }
  }
  & .apexcharts-xaxistooltip,
  & .apexcharts-yaxistooltip {
    background: var(--expenses-dashboard-custom-back);
    color: var(--header-title-color);
  }
  & .apexcharts-xaxis-label,
  & .apexcharts-yaxis-label {
    fill: var(--header-subtitle-color);
  }
}
</style>
