<template lang="pug">
div
  index-page(
    @set-loading="setLoading",
    :parentData="{ custom_grid: grid, grid: grid, path: path, grid_key: grid_key, forms: { filter_data: [] } }"
  )

  ui-prompts(:parentData="{ areas_key: grid }")
</template>

<script>
import indexPage from "../../../shared/indexPage";
import uiPrompts from "../../../shared/ui_prompts/index.vue";

export default {
  components: {
    indexPage,
    uiPrompts,
  },
  data: function () {
    return {
      grid: "ppr_calendar",
      path: this.$store.state.paths["ppr_calendar"],
      grid_key: "data",
    };
  },

  beforeMount() {
    // this.$emit('expand-class', {val: false, class: ''})
    this.$emit("expand-class", { val: true, class: "dashboard-background" });
  },

  methods: {
    setLoading(val) {
      this.$emit("set-loading", val);
    },
  },
};
</script>

<style lang="scss"></style>
