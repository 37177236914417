<template lang="pug">
#map-page
  #map-container
    #map

  EntityView(v-if="selectedEntity", :entity="selectedEntity", @closed="selectedEntity = null")
</template>

<script>
import { load } from "@2gis/mapgl";
import Worker from "./models/Worker";
import Vehicle from "./models/Vehicle";
import Building from "./models/Building";
import Header from "../../shared/helpers/Header";

import VehicleMarker from "./models/VehicleMarker";
import WorkerMarker from "./models/WorkerMarker";
import BuildingMarker from "./models/BuildingMarker";
import EmergencyMarker from "./models/EmergencyMarker";
import EntityMarkerMap from "./models/EntityMarkerMap";
import Tooltip from "./models/Tooltip";
import TooltipEntityView from "./models/TooltipEntityView";
import EntityView from "./EntityView";
import SearchControl from "./controls/Search";
import FilterPanelControl from "./controls/FilterPanel";
import ZoomControl from "./controls/ZoomControl";

import Map from "./models/Map";

import searchFilter from "./entity_filters/search";
import kindFilter from "./entity_filters/kind_filter";

import { handleError } from "@/services/handleErrors";

/*
 * OPTIMIZE: See some ideas below.
 *
 * Markers and entities
 * ====================
 * Usually you want to load only objects that are within your current map area similar to tiles.
 * It's quite complicated to do but if you end up with LOTS and LOTS of entities
 * it may be your only option.
 *
 */

export default {
  components: {
    Header,
    EntityView,
  },
  data: function () {
    return {
      buildings: [],
      workers: [],
      vehicles: [],
      selectedEntity: null,
      entityMarkerMap: new EntityMarkerMap(),
      map: null,
      api: null,
      tooltip: null,
      intervals: [],
      entityFilters: {
        searchQuery: null,
        kinds: new Set(),
      },
    };
  },

  computed: {
    allEntities() {
      return this.buildings.concat(this.workers).concat(this.vehicles);
    },
    visibleEntities() {
      let result = this.allEntities;
      result = kindFilter(this.entityFilters.kinds, result);
      result = searchFilter(this.entityFilters.searchQuery, result);

      return new Set(result);
    },
    entitiesByKey() {
      return this.allEntities.reduce((a, e) => Object.assign(a, { [e.getEntityKey()]: e }));
    },
  },

  watch: {
    visibleEntities() {
      this.updateMarkersVisibility();
    },
  },

  mounted() {
    setTimeout(async () => {
      try {
        this.api = await load();

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async pos => await this.initMap(pos),
            async () => await this.initMap([37.617635, 55.755814]),
          );
        } else {
          await this.initMap([37.617635, 55.755814]);
        }
      } catch (e) {
        await handleError(e);
      }

      // TODO: could be faster to load entities in parallel with map
      // Beware: most of the methods rely on `this.api` and `this.map`
    }, 1000);
  },

  beforeDestroy() {
    this.destroyIntervals();
    this.tooltip.destroy();
  },

  methods: {
    async initMap(position) {
      const navigatorCenter = position.coords ? [position.coords.longitude, position.coords.latitude] : position;

      this.map = new Map(this.api, navigatorCenter);
      this.initMapControls();
      this.tooltip = new Tooltip(document.body);

      await this.loadData();

      try {
        const result = await this.getMapSettingsFromApi();

        this.initIntervals(result.data);
      } catch (e) {
        await handleError(e);
      }

      this.$emit("set-loading", false);
    },

    initMapControls() {
      new SearchControl(
        this.api,
        this.map,
        query => {
          this.entityFilters.searchQuery = query;
        },
        this.map_locales,
      );
      new FilterPanelControl(this.api, this.map, this.entityFilters.kinds, set => {
        this.entityFilters.kinds = set;
      });
      new ZoomControl(this.api, this.map);
    },

    initIntervals(mapSettings) {
      const { workers_reload_interval, vehicles_reload_interval, buildings_reload_interval } = mapSettings;

      this.destroyIntervals();
      this.intervals = [
        setInterval(async () => await this.updateWorkers(), workers_reload_interval),
        setInterval(async () => await this.updateVehicles(), vehicles_reload_interval),
        setInterval(async () => await this.updateBuildings(), buildings_reload_interval),
      ];
    },
    destroyIntervals() {
      this.intervals.forEach(clearInterval);
      this.intervals = [];
    },

    async loadData() {
      await this.updateWorkers();
      await this.updateBuildings();
      await this.updateVehicles();
    },

    createMarkerForEntity(markerClass, entity) {
      const marker = new markerClass(this.api, this.map, entity.getCoords());
      if (!this.visibleEntities.has(entity)) {
        marker.hide();
      }

      const openSidebar = () => (this.selectedEntity = entity);

      marker.addClickHandler(openSidebar);
      this.entityMarkerMap.add(entity, marker);

      const entityKey = entity.getEntityKey();
      // we shouldnt use `entity` directly bc it may get outdated at some point.
      // `entitiesByKey` contains current (updated) versions.
      const entityView = () => new TooltipEntityView(this.entitiesByKey[entityKey], this.map_locales);
      marker.addHoverHandler(
        e => this.tooltip.show(e.originalEvent, entityView(), openSidebar),
        () => this.tooltip.hide(),
      );
    },

    async updateBuildings() {
      try {
        const response = await this.getBuildingsFromApi();

        const oldBuildings = this.buildings;
        const nextBuildings = response.data.map(data => new Building(data));

        const [removedBuildingsIds, addedBuildingsIds] = this.setDiff(
          new Set(oldBuildings.map(x => x.getId())),
          new Set(nextBuildings.map(x => x.getId())),
        );

        oldBuildings.filter(b => removedBuildingsIds.has(b.getId())).forEach(b => this.destroyEntityMarker(b));

        nextBuildings.forEach(b => {
          if (addedBuildingsIds.has(b.getId())) {
            this.createBuildingMarker(b);
          } else {
            this.rebuildBuildingMarkerIfNeeded(b);
            this.entityMarkerMap.fetch(b).moveTo(b.getCoords());
          }
        });

        this.buildings = nextBuildings;
      } catch (e) {
        await handleError(e);
      }
    },

    // Updates both entities and markers
    async updateWorkers() {
      try {
        const response = await this.getWorkersFromApi();

        const oldWorkers = this.workers;
        const nextWorkers = response.data.map(data => new Worker(data));

        const [removedWorkersIds, addedWorkersIds] = this.setDiff(
          new Set(oldWorkers.map(x => x.getId())),
          new Set(nextWorkers.map(x => x.getId())),
        );

        oldWorkers.filter(w => removedWorkersIds.has(w.getId())).forEach(w => this.destroyEntityMarker(w));

        nextWorkers.forEach(w => {
          if (addedWorkersIds.has(w.getId())) {
            this.createMarkerForEntity(WorkerMarker, w);
          } else {
            this.entityMarkerMap.fetch(w).moveTo(w.getCoords());
          }
        });

        this.workers = nextWorkers;
      } catch (e) {
        await handleError(e);
      }
    },

    async updateVehicles() {
      try {
        const response = await this.getVehiclesFromApi();
        const oldVehicles = this.vehicles;
        const nextVehicles = response.data.map(data => new Vehicle(data));
        const [removedVehiclesIds, addedVehiclesIds] = this.setDiff(
          new Set(oldVehicles.map(x => x.getId())),
          new Set(nextVehicles.map(x => x.getId())),
        );

        oldVehicles.filter(v => removedVehiclesIds.has(v.getId())).forEach(v => this.destroyEntityMarker(v));

        nextVehicles.forEach(v => {
          if (addedVehiclesIds.has(v.getId())) {
            this.createMarkerForEntity(VehicleMarker, v);
          } else {
            this.entityMarkerMap.fetch(v).moveTo(v.getCoords());
          }
        });

        this.vehicles = nextVehicles;
      } catch (e) {
        await handleError(e);
      }
    },

    createBuildingMarker(building) {
      this.createMarkerForEntity(building.hasUrgentIssues() ? EmergencyMarker : BuildingMarker, building);
    },

    // HACK: we can't change api.Marker icon so we need to recreate it
    //       everytime building becomes an "emergency" (has urgent issues).
    rebuildBuildingMarkerIfNeeded(building) {
      const oldMarker = this.entityMarkerMap.fetch(building);

      const hasUrgentIssues = building.hasUrgentIssues();
      const needsRebuilding =
        (hasUrgentIssues && !(oldMarker instanceof EmergencyMarker)) ||
        (!hasUrgentIssues && !(oldMarker instanceof BuildingMarker));

      if (needsRebuilding) {
        this.destroyEntityMarker(building);
        this.createBuildingMarker(building);
      }
    },

    setDiff(set1, set2) {
      const removed = new Set([...set1].filter(x => !set2.has(x)));
      const added = new Set([...set2].filter(x => !set1.has(x)));

      return [removed, added];
    },

    destroyEntityMarker(entity) {
      this.entityMarkerMap.pop(entity).destroy();
    },

    getBuildingsFromApi() {
      return this.$backend.index("/api/v3/map/buildings");
    },

    getWorkersFromApi() {
      return this.$backend.index("/api/v3/map/workers");
    },

    getVehiclesFromApi() {
      return this.$backend.index("/api/v3/map/vehicles");
    },

    getMapSettingsFromApi() {
      return this.$backend.index("/api/v3/map/settings");
    },

    updateMarkersVisibility() {
      this.allEntities.forEach(entity => {
        const marker = this.entityMarkerMap.fetch(entity);
        this.visibleEntities.has(entity) ? marker.show() : marker.hide();
      });
    },
  },
};
</script>

<style lang="scss">
// These are supposed to be in dedicated files but I didnt wanna change webpack config
@import "../../../assets/styles/map/tooltip.scss";
@import "../../../assets/styles/map/controls/search.scss";
@import "../../../assets/styles/map/controls/filter-panel.scss";

#map-page {
  /* HACK: making the map fill the whole screen */
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
}

#map-container {
  flex: 1;
}

#map {
  height: 100%;
}
</style>
