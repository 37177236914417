<template lang="pug">
div
  #show-form.equipment-show
    .show-card
      q-card-section
        form-header(
          v-if="data.form_header",
          ref="form-header",
          @close-form="closeShow",
          :parentData="{ grid: grid, title: data.form_header.title + ' № ' + row_id, title_icon: 'show_header.svg' }"
        )

      .spinner-container.form-spinner(v-if="loading")
        q-spinner(color="primary", size="3em")

      .equipment-show-tabs(v-if="!loading")
        q-tabs(:value="tab.name", align="left")
          q-tab(
            v-for="tab in data.tabs",
            :key="tab.name",
            @click="addCurrentTab(tab)",
            :name="tab.name",
            :label-slot="false"
          )
            template(v-if="tab.icon", slot="default")
              inline-svg(
                :class="'tab-icon ' + tab.name + '-icon'",
                :src="require(`../../../../assets/icons/ppr/equipment/show/${tab.icon}`)"
              )

            template(v-if="tab.label", slot="default")
              span {{ tab.label }}

        q-tab-panels.equipment-show-tabs-panels(:value="tab.name")
          q-tab-panel(v-for="tab in data.tabs", :key="tab.name", :name="tab.name")
            component(
              :ref="tab.name",
              :is="getCard(tab.name)",
              @refresh-table="refreshTable",
              @open-form="openForm",
              :parentData="{ data: getData(tab.name), path: path, grid: grid, grid_key: grid_key, row: row, row_id: row_id, tab_name: tab.name }"
            )

  #modal-background-form
  ui-prompts(:parentData="{ areas_key: 'show_ppr_equipment' }")
</template>

<script>
import formHeader from "../../../shared/forms/formHeader";
import showCards from "./show/index";
import uiPrompts from "../../../shared/ui_prompts/index.vue";

export default {
  components: {
    formHeader,
    showCards,
    uiPrompts,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      row_id: this.parentData.row.id,
      query_tab: this.parentData.show_tab,
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
      data: [],
      row: {},
      loading: true,
      tab: "",
    };
  },

  computed: {},

  watch: {
    loading(newVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"].setLoading(newVal);
      }
    },
  },

  created() {
    this.$root.$on("load-show-data", row => {
      if (this.row_id && row && this.row_id === row.id) {
        if (row.show_tab && this.tab.name && this.tab.name !== row.show_tab) {
          this.query_tab = row.show_tab;
        }
        setTimeout(() => {
          this.loadShowData();
        }, 300);
      }
    });

    this.$emit("row-is-active", true);
    setTimeout(() => {
      this.loadShowData();
    }, 300);

    this.$root.$emit("set-child-route-component", true);
  },

  methods: {
    getCard(key) {
      if (key) {
        return showCards[key];
      }
    },

    getData(tab_name) {
      return this.data.tabs.find(tab => tab.name === tab_name)["data"];
    },

    addCurrentTab(tab) {
      this.tab = tab;
    },

    setLoading(val) {
      this.loading = val;
    },

    refreshTable(data) {
      this.refreshRow(data);
      this.$emit("refresh-table", data);

      if (data.action === "destroy") {
        this.$emit("close-show", this.row);
      }
    },

    refreshRow(data) {
      if (this.$refs["show_card"] && this.$refs["show_card"].length > 0) {
        this.$refs["show_card"][0].refreshRow(data);
      }
    },

    openForm(data) {
      this.$emit("open-form", data);
    },

    closeShow(row = this.row) {
      this.$emit("close-show", row);
    },

    loadShowData(params = {}) {
      this.$backend
        .show(this.path, this.row_id, { params: params })
        .then(({ data }) => {
          this.data = data["common"];
          this.row = data["common"]["data"];
          if (this.data.tabs && this.data.tabs.length > 0) {
            this.tab = this.data.tabs.find(t => t.name === this.query_tab) || this.data.tabs[0];
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/show/ppr/equipment/main";
</style>
