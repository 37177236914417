<template lang="pug">
.horizontal-filters-list
  .horizontal-filters-list__item(v-for="item in filters", :key="item.title")
    HorizontalFiltersItem(
      :filter="item",
      :isFilterActive="getActiveFilter(item)",
      :fieldsPath="getFilterFieldsPath(item)",
      :iconsByTypes="iconsByTypes",
      @filterResults="onFilterResults"
    )
</template>

<script setup>
import { ref, onMounted } from "vue";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";

import HorizontalFiltersItem from "@/components/shared/general_components/horizontalFiltersList/HorizontalFiltersItem";

const props = defineProps({
  path: { type: String, default: "" },
  fieldsPathParams: { type: Object, default: () => {} },
  iconsByTypes: { type: Object, default: () => {} },
  activeFilter: { type: String, default: "" },
});

const emit = defineEmits(["filterResults"]);

const filters = ref([]);

const onFilterResults = val => {
  emit("filterResults", val);
};

const getActiveFilter = item => {
  return item.type === props.activeFilter;
};

const getFilters = async () => {
  try {
    filters.value = (await backend.index(props.path)).data;
  } catch (error) {
    await handleError(error);
  }
};

const getFilterFieldsPath = filter => {
  const pathMain = props.fieldsPathParams.main;
  const pathOptions = props.fieldsPathParams.pathOptions;
  const id = props.fieldsPathParams.id || null;
  const paramsKeys = Object.keys(pathOptions);

  let path = `${pathMain}${id || ""}${paramsKeys.length ? "?" : ""}`;

  paramsKeys.forEach((el, index) => {
    if (pathOptions[el] === "getFromChildComponent::filter.template_id") {
      path += `${el}=${filter.template_id}`;
    } else {
      path += `${el}=${pathOptions[el]}`;
    }
    path += index === paramsKeys.length - 1 ? "" : "&";
  });
  return path;
};

onMounted(async () => {
  await getFilters();
});
</script>

<style lang="scss">
.horizontal-filters-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding-bottom: 5px;
  overflow-x: auto;
}
</style>
