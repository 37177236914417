<template lang="pug">
#form.new-edit-form(:class="formClass")
  .form-card
    q-card-section
      form-header(
        ref="form-header",
        @close-form="closeForm",
        :parentData="{ grid: grid, title: form_title, title_icon: title_icon_path }"
      )

    .spinner-container.form-spinner(v-if="loading")
      q-spinner(color="primary", size="3em")

    q-card-section(v-show="!loading")
      tabs(
        v-if="data.tabs && data.tabs.data.length > 0 && (!data.tabs.method_limit || data.tabs.method_limit !== method)",
        @fields-loaded="fieldsLoaded",
        @submit-form="submitForm",
        :parentData="{ method: method, grid: grid, data: data, item_id: item_id, building_id: building_id }"
      )

      component(
        v-else,
        :is="getForm('default_form')",
        @fields-loaded="fieldsLoaded",
        @submit-form="submitForm",
        :parentData="{ method: method, grid: grid, data: data, item_id: item_id, building_id: building_id }"
      )
</template>

<script>
import formHeader from "../../shared/forms/formHeader";
import tabs from "./new_edit_form/tabs";
import forms from "./new_edit_form/forms";

export default {
  components: {
    formHeader,
    forms,
    tabs,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      method: this.parentData.method,
      item: this.parentData.item,
      item_id: this.parentData.item_id || "",
      building_id: this.parentData.building_id || "",
      callback_params: this.parentData.callback_params,
      path: this.parentData.path,
      grid: this.parentData.grid,
      form_title: this.parentData.form_title,
      title_icon_path: this.parentData.title_icon_path,

      loading_fields: [],
      loading: true,

      form_valid: false,
    };
  },

  computed: {
    data() {
      return this.parentData.data;
    },

    formClass() {
      return {
        "new-edit-form--large": this.parentData.data?.container?.size === "large",
      };
    },
  },

  watch: {
    loading(newVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"].setLoading(newVal);
      }
    },
  },

  created() {
    this.resetForm();
    this.fieldsLoaded();
  },

  mounted() {},

  methods: {
    getForm(key) {
      if (key) {
        return forms[key];
      }
    },

    closeForm(params = {}) {
      params["method"] = this.method;
      this.$emit("close-form", params);
    },

    submitForm() {
      let params = {};
      let form = this.currentForm;

      this.checkFormValidation(form);

      if (this.form_valid) {
        this.loading = true;

        params[this.item] = Object.keys(form).reduce((result, e) => {
          if (Array.isArray(form[e]["field"]) && form[e]["selected_items"]) {
            result[e] = form[e]["selected_items"];
          } else if (Array.isArray(form[e]["field"])) {
            result[e] = form[e]["field"].map(e => e["value"]);
          } else if (typeof form[e]["field"] === "object") {
            result[e] = form[e]["field"]["value"];
          } else {
            result[e] = form[e]["field"];
          }
          return result;
        }, {});

        this.$backend[this.method](this.path, this.item_id, params)
          .then(res => {
            if (res.data) {
              this.$nextTick(() => {
                let res_params = {};
                res_params["data"] = res.data;
                res_params["method"] = this.method;
                this.$emit("close-form", res_params);
                this.loading = false;

                this.showCreatedNotification(res.data, this.method);
              });
            }
          })
          .catch(error => {
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.$nextTick(() => {
              this.loading = false;

              // Unknown exception occured
              if (!error.response.data.errors) {
                this.$q.notify(this.notifies.error_ask_admin);
                return;
              }

              // Popup validation error message
              const baseErrors = error.response.data.errors.base;
              const passwordErrors = error.response.data.errors.password;

              // Custom validation error messages in form
              // inn - company
              // contractor_company_ids, snils - user
              // contract_started_at, contract_finished_at, estimated_price - cleaning tariff
              const namesToShowErrorInForm = [
                "inn",
                "contractor_company_ids",
                "snils",
                "contract_started_at",
                "contract_finished_at",
                "estimated_price",
              ];
              const companyInnErrors = error.response.data.errors.inn;
              let customErrorsCount = 0;

              // Show form custom validations
              if (error.response.data.errors) {
                namesToShowErrorInForm.forEach(name => {
                  const fieldErrors = error.response.data.errors[name];

                  if (fieldErrors) {
                    this.makeFieldInvalid(name, fieldErrors.join(", "));
                    customErrorsCount += 1;
                  }
                });
              }

              if (baseErrors) {
                baseErrors.forEach(e => {
                  this.$q.notify(e.message || e);
                });
              } else if (passwordErrors) {
                passwordErrors.forEach(e => {
                  this.$q.notify(e);
                });
              } else if (companyInnErrors) {
                // Display popup error since inn is hidden in form
                companyInnErrors.forEach(e => {
                  this.$q.notify(e);
                });
              } else if (customErrorsCount > 0) {
                this.$q.notify(this.notifies.fix_invalid_fields);
              } else {
                this.$q.notify(this.notifies.error_ask_admin);
              }
            });
            console.log(error);
          })
          .finally(() => {});
      } else {
        this.$q.notify(this.notifies.fix_invalid_fields);
      }
    },

    showCreatedNotification(responseData, method) {
      const row = responseData["data"] || responseData;
      const event = method === "create" ? this.notifies.created : this.notifies.updated;
      const rowId = row["id"];

      if (rowId) {
        this.$q.notify(`${this.notifies.entry_number}${rowId}${this.notifies.is_success}${event}`);
      } else {
        this.$q.notify(`${this.notifies.entry_without_number}${this.notifies.is_success}${event}`);
      }
    },

    checkFormValidation(form) {
      let invalid_form = Object.keys(form).reduce((result, e) => {
        if (form[e]["invalid"] === true) result[e] = form[e];
        return result;
      }, {});

      let invalid_fields = Object.keys(invalid_form);

      if (invalid_fields.length > 0) {
        this.form_valid = false;

        this.$store.commit("createFormField", { grid_name: this.grid, field: "invalid_fields" });

        let val = {};
        invalid_fields.forEach(f => (val[f] = true));
        this.$store.commit("updateFormField", { grid_name: this.grid, field: "invalid_fields", value: val });
      } else {
        this.form_valid = true;
        this.$store.commit("resetFormField", { grid_name: this.grid, field: "invalid_fields" });
      }
    },

    fieldsLoaded(field = undefined) {
      if (this.loading_fields.length === 0) {
        this.loading_fields = this.data.fields
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (field) {
        this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString());
      }

      if (this.loading_fields.length === 0) {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },

    // Force add validation error to a field inside form
    makeFieldInvalid(fieldName, message) {
      const existingValue = this.currentForm[fieldName].field;
      const existingInvalidFields = this.currentForm.invalid_fields || {};
      const existingInvalidFieldsErrors = this.currentForm.invalid_fields_errors || {};

      // Set error state
      this.$store.commit("updateFormField", {
        grid_name: this.grid,
        field: fieldName,
        value: { invalid: true, field: existingValue },
      });
      // Show red border
      this.$store.commit("updateFormField", {
        grid_name: this.grid,
        field: "invalid_fields",
        value: { ...existingInvalidFields, [fieldName]: true },
      });

      // Set error message
      this.$store.commit("updateFormField", {
        grid_name: this.grid,
        field: "invalid_fields_errors",
        value: { ...existingInvalidFieldsErrors, [fieldName]: message },
      });
    },
  },

  channels: {},
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/new-edit-form";
</style>
