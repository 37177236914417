import selectField from "./selectField";
import stringField from "./stringField";
import textField from "./textField";
import floatField from "./floatField";

export default {
  select: selectField,
  string: stringField,
  text: textField,
  float: floatField,
};
