<template lang="pug">
#show-form.contract-show
  .show-card
    q-card-section
      form-header(
        v-if="showData.common",
        ref="formHeaderRef",
        @close-form="closeShow",
        :parentData="{ grid: grid, title: `${showData.common.form_header.title} № ${rowId}`, title_icon: 'show_header.svg' }"
      )

    .spinner-container.form-spinner(v-if="loading")
      q-spinner(color="primary", size="3em")

    div(v-if="!loading")
      q-card-section
        component(
          v-if="showData.common['actions']",
          :is="getShowComponent('actions')",
          @refresh-table="refreshTable",
          @open-form="openForm",
          @click="onActionClick",
          :parentData="{ row: row, grid: grid, grid_key: gridKey, path: path, data: showData.common['actions'] }"
        )

      div
        .qr-preview
          qr-image(:url="qrObject.url")
        qr(
          v-show="false",
          ref="printRef",
          :title="qrObject.form.header",
          :texts="qrObject.form.fields",
          :url="qrObject.url"
        ) 

      q-card-section
        component(
          v-if="showData.common['blocks']",
          :is="getShowComponent('blocks')",
          :parentData="{ row: row, show_details: false, data: showData.common['blocks'] }"
        )

      q-card-section
        component(
          ref="tabsRef",
          v-if="showData.common['tabs']",
          :is="getShowComponent('tabs')",
          :parentData="{ data: showData.common['tabs'] }"
        )

      q-card-section
        component(
          v-if="showData.common['form_data']",
          :is="getShowComponent('form_data')",
          @refresh-table="refreshTable",
          @set-oading="setLoading",
          :parentData="{ item: item, row: row, grid: grid, path: path, data: showData.common['form_data'] }"
        )
</template>

<script setup>
import { ref, computed, onBeforeMount, watchEffect } from "vue";
import { openTabAndPrintContent } from "@/services/print";
import { handleError } from "@/services/handleErrors";
import { useEmitter } from "@/services/useEmitter";
import backend from "@/api";
import formHeader from "../../shared/forms/formHeader";
import showComponents from "../../shared/show_card_components/index";
import qrImage from "../../shared/print/qrImage";
import qr from "../../shared/print/qr";
import compiledPrintQrCss from "!raw-loader!sass-loader!../../../assets/styles/printQr/qr.scss";

const props = defineProps({
  parentData: { type: Object, default: () => {} },
});

const emit = defineEmits(["row-is-active", "open-form", "close-show", "refresh-table"]);

const emitter = useEmitter();
const item = "room";

const row = ref({});
const loading = ref(true);
const showData = ref([]);
const tabsRef = ref(null);
const formHeaderRef = ref(null);
const printRef = ref(null);

const path = computed(() => props.parentData.path);
const rowId = computed(() => props.parentData.row.id);
const grid = computed(() => props.parentData.grid);
const gridKey = computed(() => props.parentData.grid_key);
const qrObject = computed(() => showData.value.common.qr);

emitter.on("reset-show-modal-component", row => refreshRow(row));

watchEffect(() => {
  formHeaderRef.value?.setLoading(loading.value); // Update header on loading change
});

const openForm = data => {
  emit("open-form", data);
};

const closeShow = (row = row.value) => {
  emit("close-show", row);
};

const refreshRow = data => {
  row.value = data.attr.data || data.attr;
};

const refreshTable = data => {
  refreshRow(data);
  emit("refresh-table", data);

  if (data.action === "destroy") {
    emit("close-show", row.value);
  }
};

const onActionClick = name => {
  if (name != "print_qr") {
    return;
  }

  openTabAndPrintContent({ html: printRef.value.$el.innerHTML, css: compiledPrintQrCss });
};

const loadShowData = async () => {
  try {
    const response = await backend.show(path.value, rowId.value);

    showData.value = response.data;
    row.value = showData.value.common["data"];
  } catch (error) {
    await handleError(error);
  } finally {
    loading.value = false;
  }
};

onBeforeMount(async () => {
  emit("row-is-active", true);
  await loadShowData();
});
</script>

<style scoped lang="scss">
@import "../../../assets/styles/show/contract/main";

.qr-preview {
  display: flex;
  justify-content: center;
}
</style>
