<template lang="pug">
.index-page.object-pass.dashboard
  .spinner-container.grid-spinner(v-if="!loading && loading_data")
    q-spinner(color="primary", size="3em")

  div(v-if="!loading")
    common-header(
      v-show="!loading_data",
      @reload-data="reloadData",
      :parentData="{ data: data, grid: grid, item: item, hide_filters: true, hide_buttons: true, actions: header_actions }"
    )

  q-tabs.object-pass-tabs(v-if="!loading && !loading_data", v-model="tab.name", align="left")
    q-tab(
      v-for="tab in data.tabs",
      :key="tab.name",
      @click="addCurrentTab(tab)",
      :name="tab.name",
      :label-slot="false"
    )
      template(v-if="tab.label", slot="default")
        span {{ tab.label }}

  q-tab-panels.object-pass-tabs-panels(:value="tab.name")
    q-tab-panel(v-for="tab in data.tabs", :key="tab.name", :name="tab.name")
      component(
        :ref="tab.name",
        v-show="!loading_data",
        :is="getTab(tab.name)",
        :parentData="{ path: path, data: data.boards, building: building, lists: lists, passport_fields: passport_fields }",
        @reload="reloadData"
      )
</template>

<script>
import commonHeader from "../../shared/helpers/Header";
import tabs from "./tabs";

export default {
  components: {
    commonHeader,
    tabs,
  },
  data: function () {
    return {
      grid: "object_pass",
      lists: ["issues"],
      item: "object_pass",
      path: this.$store.state.paths.object_pass,
      data: {},
      loading: true,
      loading_data: true,
      building: {},
      passport_fields: [],
      header_actions: ["project_filter_form"],
      tab: "",
    };
  },

  watch: {
    loading(newVal) {
      this.$emit("set-loading", this.loading);

      // setTimeout(() => {
      this.loading_data = newVal;
      // }, 1500)
    },
  },

  created() {
    // if (this.$route.params.building) {
    //   this.building = this.$route.params.building
    // }
    //
    // if (this.$route.query.building_id) {
    //   this.building.id = this.$route.query.building_id
    // }

    this.lists.forEach(list => {
      this.$store.commit("initialGridsState", { grid: list, attr: {} });
    });
  },

  beforeMount() {
    this.$emit("expand-class", { val: true, class: "dashboard-background" });
    this.loadDashboardData();
  },

  methods: {
    addCurrentTab(tab) {
      this.tab = tab;
    },

    getTab(tab) {
      if (tab) {
        return tabs[tab];
      }
    },

    reloadData() {
      this.loadDashboardData();
      if (this.$refs[this.tab.name]) {
        this.$refs[this.tab.name][0].reloadData();
      }
    },

    loadDashboardData(params = {}) {
      if (this.$route.query.building_id) {
        params["building_id"] = this.$route.query.building_id;
      }

      if (this.filters) {
        params.filters = this.generateFiltersParams();
      }

      this.$backend
        .index(`${this.path}`, { params: params })
        .then(({ data }) => {
          this.data = data;
          this.building = data.building;
          this.passport_fields = data.boards.passport_fields;
          this.passport_fields.forEach(item => {
            item.create = false;
            item.active = false;
          });

          if (this.data.tabs && this.data.tabs.length > 0) {
            this.tab = this.data.tabs[0];
          }
        })
        .catch(error => {
          if (error.response) {
            this.reLogin(error.response.status);
          }
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
  },
};
</script>

<style lang="scss">
.dashboard-background {
  background-color: var(--dashboard-background-color) !important;
}

.index-page.object-pass.dashboard {
  min-height: 100vh;

  .spinner-container.grid-spinner {
    height: 100%;
  }
}

.object-pass-tabs {
  max-width: 250px;
}
</style>
