<template lang="pug">
div
  div(
    v-if="dot.type != 'rooms'",
    ref="dot",
    :class="`floor-plan-dot`",
    :style="{ left: `${dot.x}%`, top: `${dot.y}%`, zIndex: prompt ? 10 : 9 }",
    @mouseover="showPrompt",
    @mouseleave="hidePrompt",
    @mousedown="dragStart",
    @mouseup="dragEnd"
  )
    inline-svg.floor-plan-dot-icon(v-if="dot.icon_url", :src="dot.icon_url", alt="icon") 
    q-icon.floor-plan-dot-icon-remove(name="cancel", v-if="editable && !this.dot.create", @click.stop="removeDot()")

    PointPrompt(
      v-show="prompt",
      ref="prompt",
      :promptData="{ dot: dot, floor_plan_id: floor_plan_id }",
      :style="{ left: promptX + 'px', top: promptY + 'px' }",
      :class="`floor-plan-dot-prompt ${promptAdditional}`"
    )
    PointCreate(
      v-if="dot.create && editable",
      :parentData="{ dot: dot, floor_plan_id: floor_plan_id, types: types, floor_id: floor_id, building_id: building_id }",
      @setDot="setDot",
      @hide="hideCreateDot"
    )
    PointDetail(v-if="active", :detailData="{ dot: dot, active: active }", @hide="hideDetail")
  .floor-plan-area-wrapper(
    v-if="dot.type == 'rooms'",
    :style="{ left: `${dot.x}%`, top: `${dot.y}%`, width: `${dot.width}%`, height: `${dot.height}%` }"
  )
    div(
      :class="`floor-plan-dot area ${dot.figure_type}`",
      ref="dot",
      :style="{ zIndex: prompt ? 3 : 2, border: `${dot.border_width}px solid ${dot.color}`, transform: `rotate(${dot.rotate}deg)` }",
      @mousedown="dragStart",
      @mouseup="dragEnd",
      @mouseover="showPrompt",
      @mouseleave="hidePrompt",
      @click="setCurrent"
    )
    .tensile-zone(@mousedown="resizeStart", @mouseup="resizeEnd", v-if="current")
      q-icon(name="open_in_new_down")
    PointPrompt(
      v-show="prompt",
      ref="prompt",
      :promptData="{ dot: dot, floor_plan_id: floor_plan_id }",
      :style="{ left: promptX + 'px', top: promptY + 'px' }",
      :class="`floor-plan-dot-prompt ${promptAdditional}`"
    )
</template>

<script>
import PointPrompt from "./point/prompt.vue";
import PointDetail from "./point/detail.vue";
import PointCreate from "./point/create.vue";

export default {
  components: {
    PointPrompt,
    PointDetail,
    PointCreate,
  },

  props: {
    dotData: {
      type: Object,
      default: () => {},
    },
    current: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: function () {
    return {
      dot: this.dotData.dot,
      floor_plan_id: this.dotData.floor_plan_id,
      floor_id: this.dotData.floor_id,
      building_id: this.dotData.building_id,
      types: this.dotData.types,
      dotInfo: {},
      active: false,
      editable: this.dotData.editable,
      prompt: false,
      promptX: 0,
      promptY: 0,
      promptAdditional: "",
      dragging: false,
      img: {},
      prevWidth: Number,
      prevHeight: Number,
      resizeX: Number,
      resizeY: Number,
      resizing: false,
      dotEl: Object,
      prevX: Number,
      prevY: Number,
      areaLayerX: Number,
      areaLayerY: Number,
    };
  },

  methods: {
    showPrompt(e) {
      if (!this.dragging && !this.dot.create) {
        const promptEl = this.$refs.prompt.$el;
        const dot = this.$refs.dot;
        this.promptX =
          this.dot.type === "rooms"
            ? dot.getBoundingClientRect().left + dot.getBoundingClientRect().width / 2
            : dot.getBoundingClientRect().left;
        this.promptY = dot.getBoundingClientRect().top;
        if (dot.getBoundingClientRect().top - promptEl.getBoundingClientRect().height - 30 <= 0) {
          this.promptAdditional = "reverse";
          if (this.dot.type === "rooms") {
            this.promptY = dot.getBoundingClientRect().top + dot.getBoundingClientRect().height;
          }
        }
        this.prompt = true;
      }
    },

    setDot(data) {
      this.$emit("setDot", data);
      this.$emit("setCurrent", this.dot);
    },

    hidePrompt() {
      this.prompt = false;
      this.promptAdditional = "";
    },

    setCurrent() {
      this.$emit("setCurrent", this.dot);
    },

    // showDetail() {
    //   if (!this.dragging && !this.dot.create && this.dot.type !== "sensors") {
    //     this.active = true;
    //   }
    // },

    setType(type) {
      this.dot.type = type;
    },

    hideDetail() {
      this.active = false;
    },

    round(number) {
      return Math.round(parseFloat(number) * 1000) / 10;
    },

    hideCreateDot() {
      this.$emit("hideCreate");
    },

    removeDot() {
      this.$emit("remove");
    },

    updateDot() {
      const plan_points = {
        x: this.dot.x,
        y: this.dot.y,
      };
      if (this.dot.type === "rooms") {
        plan_points["width"] = this.dot.width;
        plan_points["height"] = this.dot.height;
      }
      this.$backend.update(`api/v3/plan_points`, this.dot.id, { plan_point: plan_points }).then(res => {
        this.dragging = false;
      });
      this.$emit("unsetResize");
    },

    resizeStart(e) {
      if (this.editable) {
        this.prevWidth = this.dot.width;
        this.prevHeight = this.dot.height;
        this.resizing = true;
        this.resizeX = e.x;
        this.resizeY = e.y;
        const img = e.target.parentElement.parentElement.parentElement.offsetParent;
        this.img["width"] = img.getBoundingClientRect().width;
        this.img["height"] = img.getBoundingClientRect().height;
        this.img["top"] = img.getBoundingClientRect().top;
        this.img["left"] = img.getBoundingClientRect().left;
        document.addEventListener("mousemove", this.resize);
      }
    },

    resize(e) {
      if (this.resizing) {
        this.dot.width -= ((this.resizeX - e.x) / this.img["width"]) * 100;
        this.dot.height -= ((this.resizeY - e.y) / this.img["height"]) * 100;
        this.resizeX = e.x;
        this.resizeY = e.y;
        if (
          (window.innerWidth - 900) / 2 - e.x > 0 ||
          (window.innerWidth - 900) / 2 + 900 < e.x ||
          (window.innerHeight - 500) / 2 - e.y - 30 > 0 ||
          (window.innerHeight - this.img.height) / 2 - e.y - 30 > 0 ||
          (window.innerHeight - 500) / 2 + 500 - 30 < e.y ||
          (window.innerHeight - this.img.height) / 2 + this.img.height - 30 < e.y
        ) {
          document.removeEventListener("mousemove", this.resize);
          this.dot.width = this.prevWidth;
          this.dot.height = this.prevHeight;
          return;
        }
      }
    },

    resizeEnd(e) {
      document.removeEventListener("mousemove", this.resize);
      this.resizing = false;
      this.updateDot();
    },

    dragStart(e) {
      if (this.editable && !this.dot.create && !e.target.classList.contains("floor-plan-dot-icon-remove")) {
        e.preventDefault();
        this.prompt = false;
        this.dragging = true;
        const dot = e.target.closest(".floor-plan-dot");
        const img = e.target.closest(".floor-plan-editor-wrapper");
        this.img["width"] = img.getBoundingClientRect().width;
        this.img["height"] = img.getBoundingClientRect().height;
        this.img["top"] = img.getBoundingClientRect().top;
        this.img["left"] = img.getBoundingClientRect().left;
        this.dotEl = dot;
        this.prevX = this.dot.x;
        this.prevY = this.dot.y;
        this.areaLayerX = e.layerX;
        this.areaLayerY = e.layerY;
        document.addEventListener("mousemove", this.drag);
      }
    },

    drag(e) {
      if (this.dragging) {
        this.prompt = false;
        const currentPositionY = e.y - this.img.top - this.areaLayerY;
        const currentPositionX = e.x - this.img.left - this.areaLayerX;
        const y = this.round(currentPositionY / this.img.height);
        const x = this.round(currentPositionX / this.img.width);
        this.dot.y = y;
        this.dot.x = x;
        if (
          (window.innerWidth - 900) / 2 - e.x > 0 ||
          (window.innerWidth - 900) / 2 + 900 < e.x ||
          (window.innerHeight - 500) / 2 - e.y - 30 > 0 ||
          (window.innerHeight - this.img.height) / 2 - e.y - 30 > 0 ||
          (window.innerHeight - 500) / 2 + 500 - 30 < e.y ||
          (window.innerHeight - this.img.height) / 2 + this.img.height - 30 < e.y
        ) {
          document.removeEventListener("mousemove", this.drag);
          this.dot.x = this.prevX;
          this.dot.y = this.prevY;
          return;
        }
      }
    },

    dragEnd(e) {
      if (this.dragging) {
        e.preventDefault();
        if (this.prevX !== this.dot.x || this.prevY !== this.dot.y) {
          this.updateDot();
        } else {
          this.dragging = false;
        }
        document.removeEventListener("mousemove", this.drag);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/floor-plan/main";
</style>
