<template lang="pug">
.horizontal-filters__field
  .text-bold.text-body1.q-pb-sm.horizontal-filters__field-label {{ field.name }}
    q-icon.text-h6.horizontal-filters__icon-reset(
      name="refresh",
      @click="resetSelectField",
      v-if="horizontalFilterSelectField.length > 0"
    )
  .form-field.form-multiselect
    Multiselect(
      :value="horizontalFilterSelectField",
      :options="horizontalFilterSelectFieldOptions",
      placeholder="",
      :multiple="true",
      :hideSelected="true",
      :show-labels="false",
      open-direction="bottom",
      :allow-empty="true",
      @select="setAnswer",
      @remove="removeAnswer",
      :close-on-select="false"
    )
      span(slot="noResult")
        i {{ notifies.no_search_result }}

      span(slot="noOptions")
        i {{ notifies.no_options_list }}
</template>

<script setup>
import { ref } from "vue";

import Multiselect from "vue-multiselect";

const props = defineProps({
  field: { type: Object, default: () => {} },
  currentFieldState: { type: Object, default: () => {} },
});

const emit = defineEmits(["answerSet"]);

const horizontalFilterSelectField = ref([]);
const horizontalFilterSelectFieldOptions = ref(props.field.params.options || []);

if (props.currentFieldState.field_id) {
  horizontalFilterSelectField.value = props.currentFieldState.inclusion;
}

const setAnswer = (val, reset) => {
  if (!reset) {
    horizontalFilterSelectField.value.push(val);
  }
  emit("answerSet", { field_id: props.field.field_id, type: "dropdown", inclusion: horizontalFilterSelectField.value });
};
const removeAnswer = val => {
  const answerIndex = horizontalFilterSelectField.value.indexOf(val);

  if (answerIndex !== -1) {
    horizontalFilterSelectField.value.splice(answerIndex, 1);
  }

  emit("answerSet", { field_id: props.field.field_id, type: "dropdown", inclusion: horizontalFilterSelectField.value });
};

const resetSelectField = () => {
  horizontalFilterSelectField.value = [];
  setAnswer("-", true);
};

const onAllFilterReset = () => {
  horizontalFilterSelectField.value = [];
};

defineExpose({ onAllFilterReset });
</script>

<style lang="scss"></style>
