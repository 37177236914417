<template lang="pug">
.index-page.telemetry.dashboard
  .spinner-container.grid-spinner(v-if="!loading && loadingData")
    q-spinner(color="primary", size="3em")

  div(v-if="!loading")
    common-header(
      v-show="!loadingData",
      @reload-data="reloadData",
      :parentData="{ data: data, grid: grid, item: item, hide_filters: true, hide_buttons: true, actions: headerActions }"
    )

  .telemetry-blocks(v-if="!loading && !loadingData")
    .telemetry-blocks-top
      .telemetry-blocks-floor(:class="{ 'hidden-block': isLeftBlockHidden }")
        .card(v-if="!isLeftBlockHidden")
          floors(
            ref="floors",
            @set-floor="setFloor",
            @load-rooms="loadRooms",
            @load-sensors="loadSensors",
            :parentData="{ data: data.boards.floors, building: building, floors: floors, floor: floor }"
          )
          rooms(
            ref="rooms",
            @set-room="setRoom",
            @load-sensors="loadSensors",
            :parentData="{ data: data.boards.rooms, building: build, room: room }"
          )
        .card(v-else)
        q-btn.hide(icon="chevron_left", round, flat, size="14px", @click="hideLeftBlock")
      .telemetry-blocks-sensors
        building-info(:parentData="{ building: building }")
        sensors(ref="sensors", @load-indications="loadIndications", :parentData="{ data: [] }")
      .telemetry-blocks-indications(
        :class="{ 'shrink-block-for-two': $refs.indications ? $refs.indications.indications.length <= 2 : false }"
      )
        indications(
          ref="indications",
          @load-indications-by-hours="loadIndicationsByHours",
          :parentData="{ data: data.boards.indications }"
        )
    .telemetry-blocks-bottom
      .telemetry-blocks-floor-plan
        floor-plan(ref="floor-plan", :parentData="{ building: building, floors: floors, floor: floor, room: room }")
      .telemetry-blocks-indications-hours
        indications-by-hours(
          @open-modal="openModal",
          ref="indications-by-hours",
          :parentData="{ grid: lists[0], data: data.boards.indications_by_hours }"
        )

  q-dialog(v-model="modal.state")
    telemetry-modal(@modal-callback="modalCallback", :parentData="modal.data")
</template>

<script>
import commonHeader from "../../shared/helpers/Header";
import floors from "./boards/floors";
import rooms from "./boards/rooms";
import camera from "./boards/camera";
import buildingInfo from "./boards/building_info";
import sensors from "./boards/sensors";
import floorPlan from "./boards/floor_plan";
import indications from "./boards/indications";
import indicationsByHours from "./boards/indications_by_hours";
import telemetryModal from "./modal";

export default {
  components: {
    commonHeader,
    floors,
    rooms,
    camera,
    buildingInfo,
    sensors,
    floorPlan,
    indications,
    indicationsByHours,
    telemetryModal,
  },
  data: function () {
    return {
      grid: "telemetry",
      item: "telemetry",
      modal: {
        state: false,
        data: {},
      },
      lists: ["indications_by_hours"],
      path: this.$store.state.paths.telemetry,
      data: {},
      loading: true,
      loadingData: true,
      building: {},
      headerActions: ["project_filter_form"],
      currentFloors: [],
      currentFloor: {},
      currentRoom: {},
      isLeftBlockHidden: false,
    };
  },

  computed: {
    build() {
      return this.building;
    },

    buildingId() {
      return (this.building && this.building.id) || "";
    },

    floors() {
      return this.currentFloors;
    },

    floor() {
      return this.currentFloor;
    },

    room() {
      return this.currentRoom;
    },
  },

  watch: {
    loading(newVal) {
      this.$emit("set-loading", this.loading);

      // setTimeout(() => {
      this.loadingData = newVal;
      // }, 2500)
    },
  },

  created() {
    this.lists.forEach(list => {
      this.$store.commit("initialGridsState", { grid: list, attr: {} });
    });
  },

  beforeMount() {
    this.$emit("expand-class", { val: true, class: "dashboard-background" });
    this.loadDashboardData();
  },

  methods: {
    hideLeftBlock() {
      this.isLeftBlockHidden = !this.isLeftBlockHidden;
    },
    setFloor(floor) {
      this.currentFloor = floor;
    },

    setRoom(room) {
      this.currentRoom = room;
    },

    openModal(data) {
      this.modal.state = true;
      this.modal.data = data;
    },

    reloadData() {
      this.loadDashboardData();
    },

    loadDashboardData(params = {}) {
      if (this.$route.query.building_id) {
        params["building_id"] = this.$route.query.building_id;
      }

      if (this.filters) {
        params.filters = this.generateFiltersParams();
      }

      this.$backend
        .index(`${this.path}/data`, { params: params })
        .then(({ data }) => {
          this.data = data;
          this.building = data.building;
        })
        .catch(error => {
          if (error.response) {
            this.reLogin(error.response.status);
          }
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
            this.loadFloors();
          }, 500);
        });
    },

    loadFloors(params = {}) {
      params["building_id"] = this.buildingId;

      if (params["building_id"]) {
        this.$backend
          .collection(`${this.$store.state.paths.floor}/collection`, params)
          .then(({ data }) => {
            this.currentFloors = data.options;
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              this.reLogin(error.response.status);
            }
          })
          .finally(() => {
            this.loadRooms();
            if (this.floors.length > 0) {
              if (this.$refs.floors) {
                this.$refs.floors.hideTable(false);
              }
            } else {
              if (this.$refs.floors) {
                this.$refs.floors.hideTable(true);
              }
            }
          });
      } else {
        if (this.$refs.floors) {
          this.$refs.floors.hideTable(true);
        }
        this.loadRooms({ floor_id: "" });
      }
    },

    loadRooms(params = {}) {
      setTimeout(() => {
        if (this.$refs.rooms) {
          this.$refs.rooms.loadRooms(params);
        }
      }, 300);
    },

    loadSensors(params = {}) {
      if (this.$refs.sensors) {
        this.$refs.sensors.loadSensors(params);
      }
    },

    loadIndications(params = {}) {
      if (this.$refs.indications) {
        if (params["interval_reload"]) {
          // this.$refs.indications.reloadIndications(params)
        } else {
          this.$refs.indications.loadIndications(params);
        }
      }
    },

    loadIndicationsByHours(params = {}) {
      if (this.$refs["indications-by-hours"]) {
        if (params["interval_reload"]) {
          this.$refs["indications-by-hours"].loadChartData(params);
        } else {
          this.$refs["indications-by-hours"].reloadChart(params);
        }
      }
    },

    modalCallback(data) {
      if (this.$refs[data.ref]) {
        this.$refs[data.ref][data.fn](data.params);
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard-background {
  background-color: var(--dashboard-background-color) !important;
}

.index-page.object-pass.dashboard {
  min-height: 100vh;

  .spinner-container.grid-spinner {
    height: 100%;
  }
}

.shrink-block-for-two {
  width: 194px !important;
}

.index-page.telemetry.dashboard {
  .hidden-block {
    transition: width 0.3s ease-in;
    width: 60px !important;
    overflow: hidden !important;
    .hide {
      transition: transform, 0.3s;
      transform: rotateZ(180deg);
      right: 9px;
    }
    .card {
      transition: width 0.3s ease-in;
      width: 60px;
      overflow: visible;
      height: 100%;
    }
  }

  .hide {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 14px;
    color: var(--header-title-color);
  }

  .card {
    background: var(--dashboard-card-background);
    border-radius: 20px;
    transition: width, 0.3s;
  }

  .card-label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    color: var(--dashboard-label-color);
  }

  .telemetry-blocks {
    transition: width 0.3s ease-in;
    margin-top: 30px;
    width: 100%;
    &-top {
      gap: 20px;
      .telemetry-blocks-sensors {
        flex-grow: 1;
      }
    }
    &-top,
    &-bottom {
      display: flex;
      justify-content: space-between;
    }

    &-floor {
      width: 27%;
      filter: drop-shadow(-4px 8px 24px rgba(44, 63, 88, 0.02));
      margin-bottom: 25px;
      height: 475px;
      overflow: scroll;

      .card {
        transition: width 0.3s ease-in;
        padding: 22px 28px;
      }

      @media (max-width: 1310px) {
        width: 49%;
        order: 1;

        @media (max-width: 900px) {
          width: 100%;
        }
      }
    }

    &-sensors {
      width: 30%;
      height: 475px;
      overflow: scroll;

      @media (max-width: 1310px) {
        width: 49%;
        order: 2;

        @media (max-width: 900px) {
          width: 100%;
        }
      }
    }

    &-indications {
      width: 39%;
      height: 475px;
      overflow: scroll;

      @media (max-width: 1310px) {
        width: 49%;
        order: 3;
        min-width: initial;

        @media (max-width: 900px) {
          width: 100%;
        }
      }
    }

    &-floor-plan {
      width: 49%;

      @media (max-width: 1310px) {
        width: 100%;
        order: 5;
      }
    }

    &-indications-hours {
      width: 49%;

      @media (max-width: 1310px) {
        order: 4;

        @media (max-width: 900px) {
          width: 100%;
        }
      }
    }
  }

  .card-image {
    text-align: center;
  }

  .card-button {
    top: 105px;
    width: 100%;
    text-align: center;

    button {
      height: 34px;
      border-radius: 8px;
      background-color: #5b7aff10;
      color: #5b7aff;
      padding-left: 40px;
      padding-right: 40px;

      .q-btn__wrapper {
        min-height: initial !important;
      }

      .q-btn__content {
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}
</style>
