<template lang="pug">
div
  .form-field-label
    span {{ field.label }}

  .form-field.form-input
    q-input(filled, no-error-icon, :value="workflowInputFieldValue", autocomplete="off", @input="setField")
      q-icon.cancel-string-field(name="cancel", v-if="workflowInputFieldValue", @click.stop="resetInputField()")
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  field: { type: Object, default: () => {} },
  object: { type: Object, default: () => {} },
});

const emit = defineEmits(["fieldChange"]);

const workflowInputFieldValue = ref(props.object[props.field.name]);

const setField = val => {
  workflowInputFieldValue.value = val;
  emit("fieldChange", { name: props.field.name, value: val });
};

const resetInputField = () => {
  workflowInputFieldValue.value = "";
  emit("fieldChange", { name: props.field.name, value: "" });
};
</script>
