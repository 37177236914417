<template lang="pug">
div
  .object-pass-blocks(v-if="data && building")
    .top
      .card.common-info
        .card-image.noselect
          img(:src="require(`../../../../assets/icons/object_pass/object.svg`)")
        .card-separator
        .card-info
          .card-info-rows
            .card-info-row(v-for="element in currentObjectData.fields")
              span.title {{ element.label }}
              span.sub-title(v-if="element.type !== 'attachments'") {{ metricsCardValue(element) }}
              span.sub-title(v-else)
                a.field-value-attachment(
                  v-for="attachment in element.value",
                  :key="`${attachment.file_name} ${attachment.file_size}`",
                  target="_blank",
                  :href="attachment.url"
                ) {{ attachment.file_name }}

    .bottom
      .card.expenses
        .card-label.noselect
          span {{ data.expenses.title }}
        p {{ locales["ppr_object_passport"][current_locale]["plug"] }}

      .measuring-meters
        .card
          .card-label.noselect
            span {{ data.measuring_meters.title }}
          .card-image.noselect
            img(:src="require(`../../../../assets/icons/object_pass/measuring_meters_${theme}.svg`)")
          .card-button

      .telemetry
        .card
          .card-label.noselect
            span {{ data.telemetry.title }}
          .card-image.noselect
            img(:src="require(`../../../../assets/icons/object_pass/telemetry_${theme}.svg`)")
          .card-button
            q-btn(flat, no-caps, @click="openTelemetry()")
              template(slot="default")
                span {{ data.telemetry.buttons[0].label }}

    horizontal-filters.horizontal-filters(
      @filterResults="onFilterResults",
      :path="filtersPath",
      :fieldsPathParams="horizontalFiltersFieldsPathParams",
      :activeFilter="activeFilter",
      :iconsByTypes="iconsByTypes"
    )

    .objects_list(v-if="expansionItems.length === 1")
      q-list.expansion-list
        template(v-for="(item, i) in expansionItems[0].items")
          //expansion-list-item(
          //  :element="item",
          //  :level="1",
          //  :pathsByLevels="pathsByLevels",
          //  :horizontalFilters="horizontalFiltersContent",
          //  :ref="el => (expansionItemRefs[i] = el)"
          //)
          expansion-list-item(
            :element="item",
            :level="1",
            :horizontalFilters="horizontalFiltersContent",
            :ref="el => (expansionItemRefs[i] = el)"
          )
    .objects_list(v-else-if="expansionItems.length > 1")
      q-tabs(v-model="currentTab", dense, align="justify", narrow-indicator)
        q-tab(v-for="tab in expansionItems", :key="tab.type", :name="tab.type", :label="tab.label")
      q-tab-panels(v-model="currentTab", animated)
        q-tab-panel(v-for="tab in expansionItems", :key="tab.type", :name="tab.type")
          q-list.expansion-list
            template(v-for="(item, i) in tab.items")
              //expansion-list-item(
              //  :element="item",
              //  :level="1",
              //  :pathsByLevels="pathsByLevels",
              //  :horizontalFilters="horizontalFiltersContent",
              //  :ref="el => (expansionItemRefs[i] = el)"
              //)
              expansion-list-item(
                :element="item",
                :level="1",
                :horizontalFilters="horizontalFiltersContent",
                :ref="el => (expansionItemRefs[i] = el)"
              )

    .objects_list.q-pa-xl.q-ma-xl(v-else)
      .row.justify-center.align-center.card-label
        span {{ locales.notifies[current_locale].no_options_list }}

  grid-hidden-msg(v-show="!building", ref="object-pass-hidden-msg", :parentData="{ hide_table: hide_table }")
</template>

<script>
import { handleError } from "@/services/handleErrors";

import expensesChart from "../expenses/expensesChart";
import gridHiddenMsg from "../../../shared/grid/gridHiddenMsg";
import createForm from "../passport_fields/form";
import editForm from "../passport_fields/editForm";
import ExpansionListItem from "@/components/shared/general_components/expansionList/ExpansionListItem";
import HorizontalFilters from "@/components/shared/general_components/horizontalFiltersList/HorizontalFilters";

export default {
  components: {
    HorizontalFilters,
    ExpansionListItem,
    expensesChart,
    gridHiddenMsg,
    Grid: () => import("../../../shared/grid/Grid"),
    createForm,
    editForm,
  },
  provide() {
    return {
      topParentId: this.currentObjectData.id,
    };
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      building: this.parentData.building,
      lists: this.parentData.lists,
      passport_fields: this.parentData.passport_fields,
      grid_key: "data",
      editForm: false,
      currentParagraph: {},
      canEdit: false,
      hide_table: false,
      items: [],
      horizontalFiltersContent: {},
      horizontalFiltersFieldsPathParams: {
        main: "api/v3/tech_passport/filters/",
        id: this.parentData.building.id,
        pathOptions: {
          parent_template_id: this.parentData.building.template_id,
          template_id: "getFromChildComponent::filter.template_id",
        },
      },
      activeFilters: {},
      activeFilter: "",
      expansionItemRefs: [],
      iconsByTypes: {
        Building: "apartment",
        Floor: "meeting_room",
        Room: "chair",
        "Ppr::Equipment": "construction",
      },
      // pathsByLevels: {
      //   1: {
      //     data: "/api/v3/tech_passport/objects/",
      //     children: "/api/v3/tech_passport/objects?parent_type=Floor",
      //     type: "Floor",
      //     image: "floor_image.svg",
      //   },
      //   2: {
      //     data: "/api/v3/tech_passport/objects/",
      //     children: "/api/v3/tech_passport/objects?parent_type=Room",
      //     type: "Room",
      //     image: "room.svg",
      //   },
      //   3: {
      //     data: "/api/v3/tech_passport/objects/",
      //     children: "",
      //     type: "Ppr::Equipment",
      //   },
      // },
      expansionItems: [],
      currentObjectData: [],
      currentTab: "",
    };
  },

  computed: {
    data() {
      return this.parentData.data;
    },
    filtersPath() {
      return `/api/v3/tech_passport/filters?id=${this.building.id}&template_id=${this.building.template_id}`;
    },
  },

  watch: {
    parentData(newVal) {
      this.passport_fields = newVal.passport_fields;
    },
  },

  async mounted() {
    if (!this.building) {
      this.hideTable(true);
    }
    this.checkUserRoles();
    await this.loadBuildingData();
    await this.loadExpansionItems();
  },

  methods: {
    hideTable(val) {
      this.hide_table = val;
      if (this.$refs["object-pass-hidden-msg"]) {
        this.$refs["object-pass-hidden-msg"].hideTable(val);
      }
    },

    reloadData() {
      this.lists.forEach(list => {
        if (this.$refs[list]) {
          this.$refs[list].reloadData();
        }
      });
    },

    generateHorizontalFiltersObject(val) {
      if (val.answers.length) {
        this.horizontalFiltersContent = {
          template_id: val.template_id,
          fields: val.answers.map(elem => {
            if ((elem.type === "dropdown" || elem.type === "radio") && elem.inclusion) {
              return {
                field_id: elem.field_id,
                inclusion: elem.inclusion,
              };
            } else if (elem.type === "integer" && elem.min !== undefined && elem.max !== undefined) {
              return {
                field_id: elem.field_id,
                min: elem.min,
                max: elem.max,
              };
            }
          }),
        };
        this.activeFilter = val.type;
      } else {
        this.horizontalFiltersContent = {};
        this.activeFilter = "";
      }
    },

    // Close all items of expansion items
    closeExpansionList() {
      this.expansionItemRefs.forEach(el => {
        if (el) el.toggleExpansionItem("toggleOnRoot");
      });
    },

    async onFilterResults(val) {
      this.generateHorizontalFiltersObject(val); // create object with filters to fetch content (expansion list)
      this.closeExpansionList();
      await this.loadBuildingData(); // refetch metrics list
      await this.loadExpansionItems(); // update list of expansions items (tree structure)
    },

    getCollection(params) {
      if (this.building.id) {
        params.filters.building_id = this.building.id;
      }

      this.gridCollection(`${this.path}/${params.grid}`, params.grid, this.grid_key, params)
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.$refs[params.grid].setError(false);
        })
        .finally(() => {
          this.$refs[params.grid].setLoading(false);
        });
    },

    openMeasuringStatistics() {
      if (this.building.id) {
        this.$router.push({
          name: "measuring_statistics",
          query: { building_id: this.building.id },
        });
      }
    },

    metricsCardValue(field) {
      const isMeasureUnitShown = field.measure_unit && field.value;
      const value = isMeasureUnitShown ? `${field.value} ${field.measure_unit}` : field.value;

      return value || "-";
    },

    openTelemetry() {
      if (this.building.id) {
        this.$router.push({
          name: "telemetry",
          query: { building_id: this.building.id },
        });
      }
    },

    closeForm() {
      this.passport_fields = this.passport_fields.map(item => {
        item.create = false;
        item.active = false;
        this.currentParagraph = {};
        this.editForm = false;
        return item;
      });
    },

    checkUserRoles() {
      if (
        this.current_user.role === "superadmin" ||
        this.current_user.role === "admin" ||
        this.current_user.role === "chief_engineer"
      ) {
        this.canEdit = true;
      }
    },

    async loadExpansionItems() {
      try {
        this.expansionItems = (
          await this.$backend.index(
            `/api/v3/tech_passport/objects?parent_template_id=${this.building.template_id}&parent_id=${
              this.building.id
            }&filters=${JSON.stringify(this.horizontalFiltersContent)}`,
          )
        ).data;
        this.currentTab = this.expansionItems[0]?.type;
      } catch (error) {
        await handleError(error);
      }
    },

    async loadBuildingData() {
      try {
        const response = await this.$backend.index(
          `/api/v3/tech_passport/objects/${this.building.id}?template_id=${
            this.building.template_id
          }&filters=${JSON.stringify(this.horizontalFiltersContent)}`,
        );
        this.currentObjectData = response.data.blocks.find(block => block.type === "description");
      } catch (error) {
        await handleError(error);
      }
    },
  },
};
</script>

<style lang="scss">
.object-pass {
  .card {
    background: var(--dashboard-card-background);
    border-radius: 20px;
    position: relative;
  }

  .card-label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: var(--dashboard-label-color);
  }

  &__floors-icon {
    margin-left: -20px;
  }

  .object-pass-blocks {
    display: flex;
    overflow: auto;
    margin-top: 30px;
    flex-flow: row wrap;
    justify-content: space-between;

    .top {
      background: var(--dashboard-card-background);
      border-radius: 20px;
      width: 100%;
      display: flex;
      height: auto;

      .card-separator {
        border: 1px dashed #e8e9eb;
      }

      .common-info {
        width: 100%;
        padding: 20px;
        height: auto;

        display: flex;

        .card-button {
          margin-top: 10px;
        }

        .card-image {
          width: 14%;
          margin-right: 10px;
        }

        .card-info {
          width: 100%;
          padding-left: 20px;
          display: grid;
          height: auto;

          .card-info-rows {
            height: auto;
            display: flex;
            flex-direction: row;
            gap: 15px;
            column-gap: 60px;
            flex-wrap: wrap;
          }

          .card-info-row {
            display: grid;
            width: 20%;
          }

          .title {
            font-style: normal;
            font-size: 14px;
            line-height: 14px;
            padding-bottom: 5px;

            color: #5b7aff;
          }

          .sub-title {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;

            color: var(--dashboard-label-color);

            .field-value-attachment {
              color: #3d4c63;
              &:not(:first-child) {
                margin-left: 10px;
              }
            }
          }
        }
      }

      .passport_fields {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-auto-rows: fit-content(100%);
        grid-gap: 15px;
        width: 100%;
        height: 388.27px;
        max-height: 388.27px;
        flex-flow: row wrap;
        padding: 30px 23px;
        overflow-y: scroll;
        overflow-x: hidden;

        @media (max-width: 1300px) {
          grid-template-columns: 100%;
        }

        &-item {
          width: 100%;
          display: flex;
          position: relative;
          height: fit-content;
          padding-bottom: 5px;
          color: var(--dashboard-label-color);

          span {
            display: block;
            margin-right: 35px;
            font-weight: bold;
            cursor: pointer;
          }

          &-button {
            width: 24px;
            height: 24px;
            margin-left: 5px;
            line-height: 24px;
            font-size: 24px;
            position: absolute;
            right: 0;
            top: 0;
            color: #5b7aff;
            cursor: pointer;
          }

          &-paragraphs {
            position: absolute;
            display: block;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 350px;
            overflow-y: scroll;

            &-title {
              font-size: 12px;
              font-weight: 600;
              margin-bottom: 12px;
            }

            &-grid {
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-between;
              font-size: 14px;
              color: var(--dashboard-label-color);

              &-number {
                width: 3%;
              }

              &-name {
                width: 60%;
              }

              &-value {
                width: 25%;
              }

              &-icon {
                width: 5%;
                cursor: pointer;
              }

              &:not(:last-child) {
                margin-bottom: 15px;
              }
            }

            .card {
              filter: drop-shadow(0px 8px 24px rgba(44, 63, 88, 0.1));
              border: 1px solid #f8f8f8;
              padding: 15px 15px 30px 15px;
              z-index: 1;
            }
          }
        }

        &-paragraph-form {
          position: absolute;
        }
      }
    }

    .bottom {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .measuring-meters {
        width: 23%;
        .card {
          padding: 20px;
          height: 200px !important;

          .card-image {
            margin-top: 15px;
            height: 85px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .telemetry {
        width: 23%;
        .card {
          padding: 20px;
          height: 200px !important;

          .card-image {
            margin-top: 15px;
            height: 85px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .expenses {
        padding: 20px;
        height: 200px;
        width: 50%;
        position: relative;

        p {
          text-align: center;
          display: block;
          font-size: 14px;
          line-height: 18px;
          position: absolute;
          height: fit-content;
          width: 80%;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          color: var(--dashboard-label-color);
        }
      }
    }
    .objects_list {
      width: 100%;
    }
  }

  .object-pass-left-block {
    width: 520px;
    margin-bottom: 20px;

    .object-pass-left-block.footer {
      display: flex;
    }

    .seasonal_works {
      padding: 20px;
      height: 250px;
      width: 200px;
      margin-left: 30px;

      .card-image {
        margin-top: 15px;
        margin-bottom: 20px;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .card-image {
    text-align: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .card-button {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    /*width: 100%;*/
    text-align: center;

    button {
      height: 34px;
      width: 100%;
      max-width: 150px;
      border-radius: 8px;
      background-color: #5b7aff10;
      color: #5b7aff;
      padding-left: 40px;
      padding-right: 40px;

      .q-btn__wrapper {
        min-height: initial !important;
      }

      .q-btn__content {
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }

  .horizontal-filters {
    margin-top: 20px;
  }
}
</style>
