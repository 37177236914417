<template lang="pug">
.show-card-actions
  grid-actions(
    v-for="(handler, i) in data.handlers",
    :key="handler.name",
    ref="grid-actions",
    @refresh-table="refreshTable",
    @open-form="openForm",
    :parentData="{ index: i + 1, actions: getHandleActions(handler.name), row: row, grid: grid, grid_key: grid_key, path: path, transition_key: transition_key, btn_label: handler.label, default_class: 'grid-actions ' + handler.name, actions_icon_path: 'show/actions/' + handler.icon }"
  )

  .button-wrapper(v-for="(button, i) in data.buttons", :style="button.style.wrapper")
    q-btn.show-card-action(flat, no-caps, @click="onClickButton(button)", :style="button.style.button")
      template(v-if="button.icon && button.icon.length > 0", slot="default")
        inline-svg(
          :class="[button.name + '-icon']",
          :style="button.style.icon",
          :src="require(`../../../assets/icons/show/actions/${button.icon}`)"
        )
      template(v-if="button.label && button.label.length > 0", slot="default")
        span.action-label {{ button.label }}
</template>

<script>
import gridActions from "../grid/gridActions";

export default {
  components: {
    gridActions,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      data: this.parentData.data,
      path: this.parentData.path,
      // row: this.parentData.row,
      transition_key: this.parentData.transition_key,
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },
  },

  methods: {
    onClickButton(button) {
      const onclick = button.onclick;

      this.$emit("click", button.name);

      if (onclick) {
        this[onclick.fn_name](onclick.attr);
      }
    },

    refreshTable(data) {
      this.$emit("refresh-table", data);
    },

    openForm(data) {
      this.$emit("open-form", data);
    },

    getHandleActions(action, result = {}) {
      if (this.grid === "issue_center") {
        // remade issues actions storing
        // set issues actions by issue_center, not by services

        result = Object.keys(this.$store.state.grid[this.grid].actions)
          // removing current action
          .filter(key => key === action)
          // adding actions from store using "key" to result
          .reduce((r, key) => {
            r[key] = this.$store.state.grid[this.grid].actions[key];
            return r;
          }, {});

        if (result.handle) {
          // filtering handle from result, el.key should be included into available_transitions of this row
          result.handle = result.handle.filter(el => this.row.available_transitions.includes(el.key));
        }
      } else {
        result = Object.keys(this.currentGrid.actions)
          .filter(key => key === action)
          .reduce((r, key) => {
            r[key] = this.currentGrid.actions[key];
            return r;
          }, {});
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/show/actions";
</style>
