<template lang="pug">
q-card#form.modal-form-card
  q-card-section
    form-header(
      ref="form-header",
      @close-form="closeForm",
      :parentData="{ grid: grid, title: '', title_icon: 'project_filter.svg' }"
    )

  .spinner-container.modal-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  div
    q-card-section(v-show="!loading")
      q-form
        .row(v-for="(row, i) in data.scheme", :ref="row[0]['name']")
          div(v-for="filter in row", :key="filter.name", :class="'col-' + filter.col", style="padding: 5px")
            component(
              v-show="getFilterDataByName(filter)",
              :ref="filter.name",
              :is="getFilterComponent(getFilterDataByName(filter))",
              @filters-loaded="filtersLoaded",
              @load-options="loadSelectFilterOptions",
              :parentData="{ grid: grid, data: [getFilterDataByName(filter)], options_data: options_data }"
            )

    q-card-actions.button-group.row.justify-center(v-show="!loading")
      q-btn.modal-form-cancel(flat, no-caps, :label="buttons['cancel']", @click="closeAndResetForm()")
      q-btn.modal-form-submit(flat, no-caps, :label="buttons['submit']", @click="onSubmit()")
</template>

<script>
import formHeader from "../../../../../shared/forms/formHeader";
import filters from "../../../../../shared/filters";

export default {
  components: {
    formHeader,
    filters,
  },

  props: {
    parentData: Object,
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      loading_filters: [],
      options_data: {},
      loading: true,
    };
  },

  computed: {
    data() {
      return this.parentData.data.filters_data;
    },

    buttons() {
      return this.parentData.data.header.filters_form.form_buttons;
    },
  },

  watch: {
    loading(newVal, oldVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"].setLoading(newVal);
      }
    },
  },

  created() {
    this.$emit("load-form-data");
  },

  methods: {
    setOptionsData(data, filter_name) {
      this.$refs[filter_name][0].setOptionsData(data);
    },

    setLoading(val, filter_name) {
      this.$refs[filter_name][0].setLoading(val);
    },

    loadOptions(attr) {
      this.$emit("load-options", attr);
    },

    closeForm() {
      this.$emit("close-filters-form", {});
    },

    closeAndResetForm() {
      this.$emit("filters-form-reset");
      this.$emit("close-filters-form", {});
    },

    getFilterComponent(key) {
      if (key) {
        return filters[key.type];
      }
    },

    getFilterDataByName(filter) {
      return this.data.filters.find(f => f.name === filter.name);
    },

    filtersLoaded(filter) {
      if (this.loading_filters.length === 0) {
        this.loading_filters = this.data.filters
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      this.loading_filters = this.loading_filters.filter(f => f.sort().toString() !== filter.sort().toString());

      if (this.loading_filters.length === 0) {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },

    onSubmit() {
      this.$emit("submit-filters-form", {});
    },
  },
};
</script>

<style lang="scss">
@import "../../../../../../assets/styles/forms/modal-form";
</style>
