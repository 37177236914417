<template lang="pug">
.horizontal-filters__field
  .text-bold.text-body1.q-pb-sm.horizontal-filters__field-label {{ field.name }}

  .row
    .col-6.no-left-padding
      .form-field.form-input
        q-input(
          type="number",
          v-model="minField",
          filled,
          no-error-icon,
          autocomplete="off",
          :placeholder="minPlaceholder",
          :rules="getRulesForMinMaxField()",
          @input="setAnswer",
          :disable="disabledMinMax"
        )
          template(v-slot:append, v-if="minField")
            q-icon(name="close", @click="resetMinField")
    .col-6.no-right-padding
      .form-field.form-input
        q-input(
          type="number",
          v-model="maxField",
          filled,
          no-error-icon,
          autocomplete="off",
          :placeholder="maxPlaceholder",
          :rules="getRulesForMinMaxField()",
          @input="setAnswer",
          :disable="disabledMinMax"
        )
          template(v-slot:append, v-if="maxField")
            q-icon(name="close", @click="resetMaxField")
</template>

<script setup>
import { ref, computed } from "vue";

import { techPassportFilterLocales } from "@/services/useLocales";

const props = defineProps({
  field: { type: Object, default: () => {} },
  currentFieldState: { type: Object, default: () => {} },
});

const emit = defineEmits(["answerSet", "setDisabled", "setEnabled"]);

const minField = ref();
const maxField = ref();

const minPlaceholder = ref("-");
const maxPlaceholder = ref("-");
const disabledMinMax = ref(true);

const hasFieldMinValue = computed(
  () => props.field.params.min || props.field.params.min === 0 || props.field.params.min === "0",
);
const hasFieldMaxValue = computed(
  () => props.field.params.max || props.field.params.max === 0 || props.field.params.max === "0",
);

if (hasFieldMinValue.value) {
  minPlaceholder.value = `${techPassportFilterLocales.value.from} ${props.field.params.min || 0})`;
}

if (hasFieldMaxValue.value) {
  maxPlaceholder.value = `${techPassportFilterLocales.value.to} ${props.field.params.max || 0})`;
}

if (hasFieldMinValue.value && hasFieldMaxValue.value) {
  disabledMinMax.value = false;
}

if (props.currentFieldState.field_id) {
  minField.value = props.currentFieldState.min.toString();
  maxField.value = props.currentFieldState.max.toString();
}

const setAnswer = () => {
  emit("answerSet", {
    field_id: props.field.field_id,
    type: "integer",
    min: parseFloat(minField.value) || parseFloat(minField.value) === 0 ? parseFloat(minField.value) : undefined,
    max: parseFloat(maxField.value) || parseFloat(maxField.value) === 0 ? parseFloat(maxField.value) : undefined,
  });

  setDisabledState();
};

const resetMinField = () => {
  minField.value = null;
  setAnswer();
};

const resetMaxField = () => {
  maxField.value = null;
  setAnswer();
};

const getRulesForMinMaxField = () => {
  return [
    val =>
      !val ||
      parseFloat(val) >= parseFloat(props.field.params.min) ||
      `${techPassportFilterLocales.value.number_must_be_greater_then} ${props.field.params.min}`,
    val =>
      !val ||
      parseFloat(val) <= parseFloat(props.field.params.max) ||
      `${techPassportFilterLocales.value.number_must_be_lower_then} ${props.field.params.max}`,
  ];
};

const onAllFilterReset = () => {
  minField.value = null;
  maxField.value = null;
  emit("setEnabled");
};

const isValGreaterThenMin = val => {
  return parseFloat(val) >= parseFloat(props.field.params.min);
};

const isValLowerThenMax = val => {
  return parseFloat(val) <= parseFloat(props.field.params.max);
};

const isFieldsValid = () => {
  return !!(
    (!minField.value && !maxField.value) ||
    (minField.value &&
      maxField.value &&
      isValGreaterThenMin(minField.value) &&
      isValGreaterThenMin(maxField.value) &&
      isValLowerThenMax(minField.value) &&
      isValLowerThenMax(maxField.value))
  );
};

const setDisabledState = () => {
  if (isFieldsValid()) {
    emit("setEnabled");
  } else {
    emit("setDisabled");
  }
};

defineExpose({ onAllFilterReset });
</script>

<style lang="scss">
.no-left-padding {
  padding-left: 0;
}
.no-right-padding {
  padding-right: 0;
}
.horizontal-filters__field {
  .q-field--filled.q-field--highlighted .q-field__control:before {
    background: none;
  }
  .form-input .q-field__inner .q-field__bottom {
    padding-top: 8px !important;
  }
}
</style>
