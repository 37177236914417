<template lang="pug">
div
  .workflow-fields__field
    .form-field-label
      span {{ workflowConstructorLocales.select_required_attrs }}
    .form-field.form-multiselect
      Multiselect(
        :value="statusValidationFieldsValue",
        :track-by="'id'",
        :label="'title'",
        :options="statusValidationFieldsOptions",
        placeholder="",
        :multiple="true",
        :hideSelected="true",
        :show-labels="false",
        open-direction="bottom",
        :allow-empty="true",
        :close-on-select="false",
        @select="selectFormField",
        @remove="removeFormField"
      )
        span(slot="noResult")
          i {{ notifies.no_search_result }}
        span(slot="noOptions")
          i {{ notifies.no_options_list }}
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

import Multiselect from "vue-multiselect";
import backend from "@/api";

import { workflowConstructorLocales } from "@/services/useLocales";
import { handleError } from "@/services/handleErrors";

const props = defineProps({
  status: { type: Object, default: () => {} },
});

const emit = defineEmits(["statusValidationChanged"]);

const statusValidationFieldsOptions = ref([]);
const statusValidationFieldsValue = ref([]);

const statusValidationRequest = ref([]);

const getStatusValidationFieldsValue = computed(() => {
  return props.status.validations.map(elem => {
    return {
      attribute_id: elem.id,
      id: elem.attribute,
      title: elem.attribute_title,
    };
  });
});

const getStatusValidationFieldsOptions = async () => {
  try {
    statusValidationFieldsOptions.value = (
      await backend.collection("api/v3/workflow_validations/collection")
    ).data.options;
  } catch (e) {
    await handleError(e);
  }
};

const selectFormField = element => {
  statusValidationFieldsValue.value.push(element);

  statusValidationRequest.value = statusValidationRequest.value.filter(
    el => el.id !== element.id && el.attribute !== element.id,
  );

  if (!element.attribute_id) {
    const newValidation = {
      entity_type: "Status",
      entity_id: props.status.id,
      type: "required",
      attribute: element.id,
    };

    statusValidationRequest.value.push(newValidation);
  }

  emit("statusValidationChanged", statusValidationRequest.value);
};

const removeFormField = element => {
  statusValidationFieldsValue.value = statusValidationFieldsValue.value.filter(el => el.id !== element.id);

  statusValidationRequest.value = statusValidationRequest.value.filter(
    el => el.id !== element.id && el.attribute !== element.id,
  );

  if (element.attribute_id) {
    const newValidation = { entity_type: "Status", entity_id: props.status.id, id: element.attribute_id, _destroy: 1 };
    statusValidationRequest.value.push(newValidation);
  }

  emit("statusValidationChanged", statusValidationRequest.value);
};

onMounted(async () => {
  await getStatusValidationFieldsOptions();
  statusValidationFieldsValue.value = getStatusValidationFieldsValue.value;
});
</script>
