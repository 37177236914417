<template lang="pug">
q-dialog#status-change-form(:value="isStatusChangeFormShown", seamless, position="right", @hide="hideStatusDialog")
  q-card.workflow-form__card
    .spinner-container.form-spinner-container(v-if="loading")
      q-spinner(color="primary", size="3em")
    .row
      .col-10
        span.text-h6.text-bold.workflow-form__card__title {{ getFormCardTitle }}
      .col-2.workflow-form__card__close-icon
        q-btn(flat, round, icon="close", @click="hideStatusDialog")
    .workflow-form__card__scroll
      .row
        .col-12
          .workflow-form__field(v-for="(field, i) in form?.fields", :key="field.id")
            component(
              :is="getFieldComponent(field.type)",
              :field="field",
              :object="status",
              :ref="el => { fieldsRefs[i] = el; }",
              @fieldChange="onFieldChange",
              @disableField="disableField",
              @enableField="enableField"
            )
          WorkflowStatusValidationFields(:status="status", @statusValidationChanged="onStatusValidationChanged")
          .workflow-form__field
            ColorPicker(:color="color", @update:color="e => (color = e)", :label="workflowConstructorLocales.color")
    .row
      .col-6.workflow-form__card__button
        q-btn.workflow-form__card__button--close(flat, no-caps, @click="hideStatusDialog") {{ workflowConstructorLocales.cancel }}
      .col-6.workflow-form__card__button
        q-btn.workflow-form__card__button--accept(
          flat,
          no-caps,
          :disable="!!disabledFields.length",
          @click="updateStatus"
        ) {{ workflowConstructorLocales.save }}
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import WorkflowInputField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowInputField";
import WorkflowFloatField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowFloatField";
import WorkflowSelectField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowSelectField";
import WorkflowCheckboxField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowCheckboxField";
import WorkflowStatusValidationFields from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowStatusValidationFields";
import ColorPicker from "@/components/shared/general_components/ColorPicker";

import backend from "@/api";
import { handleError } from "@/services/handleErrors";
import { workflowConstructorLocales } from "@/services/useLocales";

const props = defineProps({
  title: { type: String, default: "" },
  status: { type: Object, default: () => {} },
  workflowId: { type: Number, default: null },
  isStatusChangeFormShown: { type: Boolean, default: false },
});

const emit = defineEmits(["updateStatus", "hideDialog"]);

const form = ref();
const currentStatus = ref({});
const color = ref(props.status?.template.color || "#5b7aff");
const disabledFields = ref([]);

const fieldsRefs = ref([]);

const loading = ref(true);

const disableField = event => {
  if (!disabledFields.value.includes(event)) {
    disabledFields.value.push(event);
  }
};

const enableField = event => {
  const fieldIndex = disabledFields.value.findIndex(field => field === event);
  if (fieldIndex !== -1) {
    disabledFields.value.splice(fieldIndex, 1);
  }
};

const onFieldChange = event => {
  currentStatus.value[event.name] = event.value;
};

const getFieldComponent = type => {
  if (type === "string") {
    return WorkflowInputField;
  } else if (type === "float") {
    return WorkflowFloatField;
  } else if (type === "select") {
    return WorkflowSelectField;
  } else if (type === "boolean") {
    return WorkflowCheckboxField;
  }
};

const hideStatusDialog = () => {
  currentStatus.value = {};
  emit("hideDialog");
};

const updateStatus = () => {
  if (checkIfColorEquals()) {
    if (props.status.template && props.status.template.position) {
      currentStatus.value.template = { position: props.status.template.position, color: color.value };
    } else {
      currentStatus.value.template = { color: color.value };
    }
  }
  if (Object.keys(currentStatus.value).length) {
    emit("updateStatus", { id: props.status.id, status: currentStatus.value });
  } else {
    hideStatusDialog();
  }
};

const checkIfColorEquals = () =>
  (props.status.template &&
    props.status.template.color &&
    color.value &&
    props.status.template.color !== color.value) ||
  (!props.status.template && color.value) ||
  (props.status.template && !props.status.template.color && color.value);

const onStatusValidationChanged = event => {
  currentStatus.value.validations_attributes = event;
};

const getFields = async () => {
  try {
    form.value = (await backend.index(`api/v3/workflows/${props.workflowId}/statuses/form`)).data;
  } catch (e) {
    await handleError(e);
  }
};

const getFormCardTitle = computed(() => {
  return `${form.value?.form_header?.title?.edit} №${props.status?.id}`;
});

onMounted(async () => {
  await getFields();
  loading.value = false;
});
</script>
