<template lang="pug">
q-menu#actions-menu(:ref="'menu-' + row.id")
  q-list(:id="listElId()")
    q-item(
      v-if="actions.handle",
      v-for="action in handleActions()",
      :key="action.name + action.key + 'el'",
      @click="clickHandleAction(action)",
      v-close-popup="1",
      clickable
    )
      q-item-section
        q-item-label {{ action.name }}
    q-separator(v-if="actions.handle && actions.crud")
    q-item(
      v-if="actions.crud",
      v-for="action in actions.crud",
      :key="action.name + action.key",
      clickable,
      v-close-popup="1",
      @click="getAction(action, row)"
    )
      q-item-section
        q-item-label {{ action.name }}
</template>

<script>
import additional_handlers from "../helpers/grid_actions_menu/additional_handlers_list";

export default {
  components: {
    additional_handlers,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
      menu_index: this.parentData.index,
      // actions: this.parentData.actions,
      // row: this.parentData.row
    };
  },

  computed: {
    actions() {
      return this.parentData.actions;
    },

    can_handle() {
      return this.row.available_transitions.length > 0;
    },

    can_crud() {
      return this.actions.crud && Object.keys(this.actions.crud).length > 0;
    },

    row() {
      return this.parentData.row;
    },
  },

  created() {
    setTimeout(() => {
      let main = document.getElementById("main");
      let ids = ["grid-" + this.grid_key, "show-form"];
      if (ids && ids.length > 0) {
        ids.forEach(id => {
          let el = document.getElementById(id);
          if (el) {
            el.addEventListener("scroll", this.closeMenu, false);
          }
        });
      }
      main.addEventListener("scroll", this.closeMenu, false);
    }, 500);
  },

  destroyed() {
    setTimeout(() => {
      let main = document.getElementById("main");
      let ids = ["grid-" + this.grid_key, "show-form"];
      if (ids && ids.length > 0) {
        ids.forEach(id => {
          let el = document.getElementById(id);
          if (el) {
            el.removeEventListener("scroll", this.closeMenu, false);
          }
        });
      }
      main.removeEventListener("scroll", this.closeMenu, false);
    }, 500);
  },

  methods: {
    listElId() {
      return this.menu_index ? "menu-" + this.menu_index : "";
    },

    // refreshRow(row) {
    //   this.row = row
    // },

    handleActions(row = this.row) {
      let handlers = [];

      if (this.actions.handle) {
        if (this.$store.state.handle_by_available_transitions_grids.includes(this.grid)) {
          handlers = this.actions.handle.filter(item => {
            return (
              row.available_transitions.includes(item.key) ||
              (additional_handlers[item.key] && row[additional_handlers[item.key]])
            );
          });
        } else {
          handlers = this.actions.handle;
        }
      }
      this.$emit("handlers-length", { row_id: this.row.id, length: handlers.length });
      return handlers;
    },

    clickHandleAction(action) {
      this.$emit("click-handle-action", action);
    },

    getAction(action, row) {
      if (
        this.actions.crud
          .map(function (el) {
            return el.key;
          })
          .includes(action.key)
      ) {
        if (action.key === "destroy") {
          let attr = {
            action: action,
            row: row,
          };
          this.$emit("destroy-row", attr);
        } else if (action.key === "edit") {
          let attr = {
            form_type: "edit",
            row: row,
            callback_params: {},
          };
          this.$emit("open-form", attr);
        } else if (action.key === "show") {
          if (action.tab_name) {
            row["show_tab"] = action.tab_name;
          }
          this.$emit("open-show", row);
        }
      } else {
        console.log(action.key);
      }
    },

    closeMenu() {
      if (this.$refs["menu-" + this.row.id]) {
        this.$refs["menu-" + this.row.id].hide();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/menu/grid_actions_menu";
</style>
