<template lang="pug">
div
  .workflow-fields__field
    .form-field-label
      span {{ workflowConstructorLocales.transition_form_fields }}
    .form-field.form-multiselect
      Multiselect(
        :value="transitionValidationFieldsValue",
        :track-by="'id'",
        :label="'title'",
        :options="transitionValidationFieldsOptions",
        placeholder="",
        :multiple="true",
        :hideSelected="true",
        :show-labels="false",
        open-direction="bottom",
        :allow-empty="true",
        :close-on-select="false",
        @select="selectFormField",
        @remove="removeFormField"
      )
        span(slot="noResult")
          i {{ notifies.no_search_result }}
        span(slot="noOptions")
          i {{ notifies.no_options_list }}

  .workflow-fields__field
    .form-field-label
      span {{ workflowConstructorLocales.select_required_form_fields }}
    .form-field.form-multiselect
      Multiselect(
        :value="transitionValidationFieldsRequiredValue",
        :track-by="'id'",
        :label="'title'",
        :options="transitionValidationFieldsValue",
        placeholder="",
        :multiple="true",
        :hideSelected="true",
        :show-labels="false",
        open-direction="bottom",
        :allow-empty="true",
        :close-on-select="false",
        @select="selectFieldRequired",
        @remove="removeFieldRequired"
      )
        span(slot="noResult")
          i {{ notifies.no_search_result }}
        span(slot="noOptions")
          i {{ notifies.no_options_list }}
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { workflowConstructorLocales } from "@/services/useLocales";

import Multiselect from "vue-multiselect";
import backend from "@/api";
import { handleError } from "@/services/handleErrors";

const props = defineProps({
  transition: { type: Object, default: () => {} },
});

const emit = defineEmits(["transitionValidationChanged"]);

const transitionValidationRequestObject = ref([]);

const transitionValidationFieldsOptions = ref([]);
const transitionValidationFieldsValue = ref([]);

const transitionValidationFieldsRequiredValue = ref([]);

const getTransitionValidationFieldsValue = computed(() => {
  return props.transition.form.transition_form_fields.map(elem => {
    return {
      id: elem.field.id,
      title: elem.field.title,
      validation_id: elem.validations[0]?.id,
      relation_id: elem.id,
    };
  });
});

const getTransitionValidationFieldsOptions = async () => {
  try {
    transitionValidationFieldsOptions.value = (await backend.collection("api/v3/fields/collection")).data.options;
  } catch (e) {
    await handleError(e);
  }
};

const getTransitionValidationFieldsRequiredValue = computed(() => {
  const transitionsWithRequired = props.transition.form.transition_form_fields.filter(elem => {
    return (
      elem.validations.filter(val => {
        return val.type === "required";
      }).length > 0
    );
  });

  return transitionsWithRequired.map(elem => ({
    id: elem.field.id,
    title: elem.field.title,
    validation_id: elem.validations[0]?.id,
    relation_id: elem.id,
  }));
});

const selectFormField = element => {
  transitionValidationFieldsValue.value.push(element);

  transitionValidationRequestObject.value = transitionValidationRequestObject.value.filter(el =>
    filterValidationsEquation(el, element),
  );

  transitionValidationRequestObject.value.push({ field_id: element.id });

  emit("transitionValidationChanged", transitionValidationRequestObject.value);
};

const removeFormField = element => {
  transitionValidationFieldsValue.value = transitionValidationFieldsValue.value.filter(el => el.id !== element.id);
  transitionValidationFieldsRequiredValue.value = transitionValidationFieldsRequiredValue.value.filter(
    el => el.id !== element.id,
  );

  transitionValidationRequestObject.value = transitionValidationRequestObject.value.filter(el =>
    filterValidationsEquation(el, element),
  );

  if (element.relation_id) {
    transitionValidationRequestObject.value.push({ field_id: 0, id: element.relation_id, _destroy: 1 });
  }

  emit("transitionValidationChanged", transitionValidationRequestObject.value);
};

const selectFieldRequired = element => {
  transitionValidationFieldsRequiredValue.value.push(element);

  const index = transitionValidationRequestObject.value.findIndex(el => findValidationEquation(el, element));

  const isFieldAlreadyPresented =
    props.transition.form.transition_form_fields.filter(el => {
      return el.field.id === element.id;
    }).length > 0;

  if (index !== -1) {
    transitionValidationRequestObject.value[index].validations_attributes = [
      { type: "required", entity_type: "TransitionFormField" },
    ];
  } else {
    isFieldAlreadyPresented
      ? transitionValidationRequestObject.value.push({
          id: element.relation_id,
          validations_attributes: [{ type: "required", entity_type: "TransitionFormField" }],
        })
      : transitionValidationRequestObject.value.push({
          field_id: element.id,
          validations_attributes: [{ type: "required", entity_type: "TransitionFormField" }],
        });
  }

  emit("transitionValidationChanged", transitionValidationRequestObject.value);
};

const removeFieldRequired = element => {
  transitionValidationFieldsRequiredValue.value = transitionValidationFieldsRequiredValue.value.filter(
    el => el.id !== element.id,
  );

  const index = transitionValidationRequestObject.value.findIndex(el => findValidationEquation(el, element));

  if (element.validation_id) {
    if (index !== -1) {
      transitionValidationRequestObject.value[index].validations_attributes = [
        { id: element.validation_id, _destroy: 1, entity_type: "TransitionFormField" },
      ];
    } else {
      transitionValidationRequestObject.value.push({
        id: element.relation_id,
        validations_attributes: [{ id: element.validation_id, _destroy: 1, entity_type: "TransitionFormField" }],
      });
    }
  } else {
    transitionValidationRequestObject.value.splice(index, 1);
  }

  emit("transitionValidationChanged", transitionValidationRequestObject.value);
};

const findValidationEquation = (currentElement, searchingElement) => {
  // if field_id presented in object we should find using field_id and searchingElement id,
  // otherwise - using id and searchingElement relation_id
  return currentElement.field_id
    ? currentElement.field_id === searchingElement.id
    : currentElement.id === searchingElement.relation_id;
};

const filterValidationsEquation = (currentElement, filterElementsParam) => {
  // if field_id presented in object we should filter using field_id and filterElementsParam id,
  // otherwise - using id and filterElementsParam relation_id
  return currentElement.field_id
    ? currentElement.field_id !== filterElementsParam.id
    : currentElement.id !== filterElementsParam.relation_id;
};

onMounted(async () => {
  await getTransitionValidationFieldsOptions();
  transitionValidationFieldsValue.value = getTransitionValidationFieldsValue.value;
  transitionValidationFieldsRequiredValue.value = getTransitionValidationFieldsRequiredValue.value;
});
</script>
