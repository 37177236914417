import selectFilter from "./select_filter";
import searchAllFilter from "./search_all_filter";
import dateFilter from "./date_filter";
import checkboxFilter from "./checkbox_filter";
import selectedDateLabel from "./selected_date_label";

export default {
  select: selectFilter,
  search_all: searchAllFilter,
  date: dateFilter,
  datetime: dateFilter,
  checkbox: checkboxFilter,
  selected_date_label: selectedDateLabel,
};
