<template lang="pug">
div
  #form.techpassport-templates-form.new-edit-form
    .form-card
      q-card-section
        div
          .form-card-title
            inline-svg.form-card-title-icon(
              v-if="titleIconPath",
              :src="require(`../../../assets/icons/form/header/${titleIconPath}`)"
            )
            span {{ templateLocales.title }}
            inline-svg.form-card-close-icon(
              v-if="!loading",
              :src="require('../../../assets/icons/form/header/close.svg')",
              @click="closeForm(formData)"
            )

          .form-card-title-spacer

      .spinner-container.form-spinner(v-if="loading")
        q-spinner(color="primary", size="3em")

      q-card-section(v-if="!loading")
        div(v-for="(item, index) in formData")
          .template-form-title {{ item.name }}
          q-form(@submit.prevent.stop="submitForm(index)")
            div(v-for="(field, indexField) in item.fields", :key="field.id || 'local' + index + 'local' + indexField")
              .template-form-field(v-if="!field._destroy")
                .field-item.field-item-checkbox
                  span.field-item-label {{ templateLocales.put_to_header }}
                  q-checkbox.form-checkbox(v-model="field.put_to_header")
                .field-item.field-item-id
                  span.field-item-label {{ templateLocales.id }}
                  q-input(
                    :class="`field-input`",
                    filled,
                    no-error-icon,
                    v-model="field.external_id",
                    :label-slot="false",
                    autocomplete="off"
                  )
                    q-icon.cancel-field.template-cancel(
                      name="cancel",
                      v-if="field.external_id",
                      @click.stop="resetField(index, indexField, 'external_id')"
                    )
                .field-item.field-item-name
                  span.field-item-label {{ templateLocales.field_title }}
                    span *
                  q-input.field-input(
                    filled,
                    no-error-icon,
                    v-model="field.name",
                    :label-slot="false",
                    autocomplete="off",
                    :rules="[val => (val && val.length > 0) || notifies.not_empty]",
                    lazy-rules
                  )
                    //- @input="validField(index, indexField, 'name')"
                    q-icon.cancel-field.template-cancel(
                      name="cancel",
                      v-if="field.name",
                      @click.stop="resetField(index, indexField, 'name')"
                    )
                .field-item.field-item-type
                  span.field-item-label {{ templateLocales.field_type }}
                    span *
                  multiselectField(
                    :multiselectClass="multiselectClasses(field)",
                    :multiselectOptions="getFieldTypeOptions(index)",
                    :multiselectValue="getFieldType(item.id, field.type)",
                    :data="formData",
                    :index="index",
                    :indexField="indexField",
                    :templateId="item.id",
                    :formType="field.type",
                    :dropdownOptions="field.params.dropdown_options",
                    :metricsValues="field.params.metrics",
                    :referenceValue="parseInt(field.params.template_id)",
                    @resetMetrics="resetMetrics",
                    @setSelectValue="setSelectValue",
                    @resetField="resetField",
                    @addDropdownVariable="addDropdownVariable",
                    @removeMultiselectItem="removeMultiselectItem",
                    @setMetricsValue="setMetricsValue",
                    @setReferencesValue="setReferencesValue",
                    @setMetricsChildValue="setMetricsChildValue"
                  )

                div(:class="measureClasses(field)")
                  span.field-item-label {{ templateLocales.measure_unit }}
                  q-input.field-input(
                    filled,
                    no-error-icon,
                    v-model="field.params.measure_unit",
                    :label-slot="false",
                    autocomplete="off",
                    :disable="disabledInput(field)"
                  )
                    q-icon.cancel-field.template-cancel(
                      name="cancel",
                      v-if="field.params.measure_unit",
                      @click.stop="resetMeasureField(index, indexField)"
                    )

                inline-svg.field-item-remove(
                  :src="require(`../../../assets/icons/object_pass/templates/remove_field.svg`)",
                  @click="removeOldField(indexField, index)"
                )
            .add-new-field(@click="addNewField(index)") 
              inline-svg.add-new-field-icon(
                :src="require(`../../../assets/icons/object_pass/templates/add_field.svg`)"
              )
              .add-new-field-text {{ templateLocales.add_attribute }}

            .button-group.row.justify-center
              q-btn.new-edit-form-submit(flat, no-caps, :label="templateLocales.submit", type="submit")
</template>

<script setup>
import Multiselect from "vue-multiselect";
import multiselectField from "./templates/multiselectField";
import "vue-multiselect/dist/vue-multiselect.min.css";

import { ref, onMounted, computed, defineProps, defineEmits } from "vue";
import { templateLocales } from "@/services/useLocales";
import { Notify } from "quasar";

import { notifies } from "@/services/useLocales";

import { handleError } from "@/services/handleErrors";
import backend from "@/api";

const props = defineProps({
  parentData: {
    type: Object,
    default() {
      return {};
    },
  },
});

const emit = defineEmits(["close-form"]);

const titleIconPath = "add_header.svg";
const facilitiesId = computed(() => props.parentData.row.id);
// const forms = props.parentData.callback_params.key === "template" ? ["building", "floor", "room"] : ["dashboard"];

const forms = ref([]);

const namespace = ref(props.parentData.callback_params.key.split("/")[0]);
const type = ref(props.parentData.callback_params.key.split("/")[1]);

const formData = ref([]);
const fieldTypes = ref([]);
const loading = ref(true);
let typeOptions = [];

const closeForm = data => {
  emit("close-form", data);
};

const submitForm = async index => {
  const params = {
    fields_attributes: [],
  };

  let invalid = false;
  let dropdownEmpty = false;

  for (let i = 0; i < formData.value[index].fields.length; i++) {
    if (!formData.value[index].fields[i]["name"] || formData.value[index].fields[i]["name"].trim() == "") {
      invalid = true;
    }
    if (!formData.value[index].fields[i]["type"]) {
      invalid = true;
    }
    if (
      formData.value[index].fields[i]["type"] === "dropdown" &&
      formData.value[index].fields[i].params["dropdown_options"].length < 1
    ) {
      dropdownEmpty = true;
    }

    const currentFieldTypeRef = fieldTypes.value.find(el => el.id === formData.value[index].id);

    const typesArray = currentFieldTypeRef.types.options;

    const currentType = typesArray.find(el => el.id === formData.value[index].fields[i].type);

    if (
      (currentType.params_type === "metric" &&
        (!formData.value[index].fields[i]["params"]["metrics"]["calculated_field"] ||
          !formData.value[index].fields[i]["params"]["metrics"]["calculated_child"])) ||
      (currentType.params_type === "reference" && !formData.value[index].fields[i]["params"]["template_id"])
    ) {
      Notify.create(templateLocales.value.invalid_metrics);
      return;
    }
  }
  if (invalid) {
    Notify.create(templateLocales.value.invalid);
    return;
  }

  if (dropdownEmpty) {
    Notify.create(templateLocales.value.dropdown_empty);
    return;
  }

  params.fields_attributes = serializeData(formData.value[index].fields);

  try {
    const { data } = await backend.update(`/api/v3/tech_passport/templates`, formData.value[index].id, params);
    formData.value[index].fields = data.fields;
    Notify.create(templateLocales.value.success);
  } catch (err) {
    if (err.message === "Request failed with status code 422") {
      Notify.create(templateLocales.value.duplicate_fields_prohibitted);
    }
  }
};

const serializeData = data => {
  let newData = [];
  data.forEach((item, index) => {
    newData.push({
      name: item.name,
      type: item.type,
      external_id: item.external_id,
      put_to_header: item.put_to_header,
      params: item.params,
    });
    if (item.id) {
      newData[index]["id"] = item.id;
    }
    if (item._destroy) {
      newData[index]["_destroy"] = item._destroy;
    }
  });
  return newData;
};

const resetMetrics = (index, indexField) => {
  formData.value[index].fields[indexField].params["metrics"] = {};
};

const resetMeasureField = (index, indexField) => {
  formData.value[index].fields[indexField]["params"]["measure_unit"] = "";
};

const addDropdownVariable = (index, indexField) => {
  formData.value[index].fields[indexField]["params"]["dropdown_options"].push("");
};

const removeMultiselectItem = (index, indexField, indexSelect) => {
  formData.value[index].fields[indexField]["params"]["dropdown_options"].splice([indexSelect], 1);
};

const nameInputClasses = valid => {
  return valid === false ? "field-input invalid" : "field-input";
};

const multiselectClasses = field => {
  let classes = ["form-field", "form-multiselect"];
  if (field.type) {
    classes.push("filled");
  }
  // else {
  //   classes.push("invalid");
  // }
  return classes.join(" ");
};

const setSelectValue = (index, indexField, val) => {
  formData.value[index].fields[indexField]["type"] = val.id;
  formData.value[index].fields[indexField]["params"]["metrics"] = {};
  if (val.id === "date") {
    resetMeasureField(index, indexField);
  }
};

const setMetricsValue = (index, indexField, id) => {
  formData.value[index].fields[indexField]["params"]["metrics"]["calculated_field"] = id;
};
const setReferencesValue = (index, indexField, id) => {
  formData.value[index].fields[indexField]["params"]["template_id"] = id;
};

const setMetricsChildValue = (index, indexField, id) => {
  formData.value[index].fields[indexField]["params"]["metrics"]["calculated_child"] = id;
};

const measureClasses = field => {
  let classes = ["field-item", "field-item-measure"];
  if (field.type === "date") {
    classes.push("disabled-field");
  }
  return classes.join(" ");
};

const disabledInput = field => {
  return field.type === "date";
};

const getFieldTypeOptions = index => {
  const template = fieldTypes.value[index];

  if (!template) {
    console.error("No template was found");
    return;
  }

  return template.types.options;
};

const getFieldTypes = async () => {
  try {
    for (let i = 0; i < forms.value.length; i++) {
      const template = forms.value[i];

      const response = await backend.index(`/api/v3/tech_passport/field_types/collection?template_id=${template.id}`);

      fieldTypes.value.push({ id: template.id, types: response.data });
    }
  } catch (e) {
    await handleError(e);
  }
};

const getTemplates = async () => {
  try {
    const response = await backend.index(
      `/api/v3/tech_passport/templates?facility_id=${facilitiesId.value}&namespace=${namespace.value}&type=${type.value}`,
    );

    forms.value = response.data;
  } catch (e) {
    await handleError(e);
  }
};

const getFields = async () => {
  try {
    for (let i = 0; i < forms.value.length; i++) {
      const template = forms.value[i];

      const { data } = await backend.index(`/api/v3/tech_passport/templates/${template.id}/edit`);

      if (!data.fields.length) {
        const field = {
          template_id: template.id,
          external_id: null,
          need_external_id: true,
          name: null,
          description: null,
          type: null,
          params: {
            measure_unit: "",
            dropdown_options: [],
            metrics: {},
          },
          put_to_header: false,
        };

        data.fields.push(field);
      }

      formData.value.push(data);
    }
  } catch (e) {
    await handleError(e);
  }
};

const addNewField = index => {
  const template = formData.value[index];

  if (!template) {
    console.error("No template was found");
    return;
  }

  const field = {
    template_id: template.id,
    external_id: null,
    need_external_id: true,
    name: null,
    description: null,
    type: null,
    params: {
      measure_unit: "",
      dropdown_options: [],
      metrics: {},
    },
    put_to_header: false,
  };

  formData.value[index].fields.push(field);
};

const removeOldField = (indexField, index) => {
  console.log(indexField, index);
  if (formData.value[index].fields[indexField].id) {
    const prevField = formData.value[index].fields[indexField];
    formData.value[index].fields.splice(indexField, 1, { ...prevField, _destroy: true });
  } else {
    formData.value[index].fields.splice(indexField, 1);
  }
};

const getFieldType = (templateId, fieldType) => {
  const template = fieldTypes.value.find(el => el.id === templateId);

  if (!template) return;

  const response = template.types.options.find(el => el.id === fieldType);

  return response || {};
};

const resetField = (index, indexField, key) => {
  formData.value[index].fields[indexField][key] = null;
};

onMounted(async () => {
  await getTemplates();
  await getFields();
  await getFieldTypes();

  loading.value = false;
});
</script>

<style lang="scss">
@import "../../../assets/styles/forms/templates-form";
</style>
